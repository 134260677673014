import { useCookies } from "react-cookie";
import { useEffect, useState } from "react";
import { readLogin } from "../../utils/firebase/firebase";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { logEvent } from "../../utils/logEvent/logEvent";

const ForceLogout = () => {
  const [email, setEmail] = useState("");
  const [tokenSecretFB, setTokenSecretFB] = useState(null);
  const [tokenSecretC, setTokenSecretC] = useState(null);
  const [dialog, setDialog] = useState({ title: "", body: "" });

  const [cookies, setCookie, removeCookie] = useCookies();

  const logout = () => {
    logEvent({ type: "logout", email: email });
    removeCookie("token", { path: "/" });
    removeCookie("tokenComposite", { path: "/" });
    removeCookie("tokenSecret", { path: "/" });
    setTimeout(() => {
      window.location.reload();
    }, 1250);
  };

  useEffect(() => {
    if (!!cookies.token) {
      setEmail(cookies.token);
    }
  }, [cookies.token]);

  useEffect(() => {
    if (!!cookies.tokenComposite) {
      readLogin(
        "loggedUser/" + cookies.tokenComposite.split("__")[0],
        (snapshot) => {
          setTokenSecretFB(
            (snapshot.val() && snapshot.val().tokenSecret) || null
          );
        }
      );
    }
  }, [cookies.tokenComposite]);

  useEffect(() => {
    if (!!cookies.tokenSecret) {
      setTokenSecretC(cookies.tokenSecret);
    }
  }, [cookies.tokenSecret]);

  useEffect(() => {
    if (
      !!tokenSecretFB &&
      !!tokenSecretC &&
      tokenSecretFB * 1 !== tokenSecretC * 1
    ) {
      setDialog({
        title:
          "Detekovali jsme přihlášení z jiného zařízení pro uživatele " + email,
        body: "V jednu chvíli může na webu videonalejvarny.cz být přihlášen uživatel jen jedenkrát. Pokračujte v prohlížení webu na nově přihlášeném zařízení. Na tomto zařízení budete za několik sekund automaticky odhlášeni.",
      });
      setTimeout(() => {
        logout();
      }, 20 * 1000);
    }
  }, [tokenSecretFB, tokenSecretC]);

  return (
    <>
      <Dialog open={dialog.title !== ""}>
        <DialogTitle>{dialog.title}</DialogTitle>
        <DialogContent style={{ marginBottom: 24 }}>
          {dialog.body}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ForceLogout;
