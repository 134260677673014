import React from "react";
import axios from "axios";
import Categories from "../Categories/Categories";
import Courses from "../Courses/Courses";
import Container from "@material-ui/core/Container";
import { baseUrl } from "../Api/Api";
import Loading from "../Loading/Loading";
import { Box, Card, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { colorHighSchool, colorUniversity } from "../Theme/Theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSchool, faUniversity } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { Element, scroller } from "react-scroll";

import {
  schoolTypeHighSchool,
  schoolTypeUniversity,
  schoolTypeXNA,
} from "../../constants/constants";
import { changeTheme } from "../../utils/utils";

const useStyles = makeStyles((theme) => {
  return {
    buttonSchool: {
      background: "#fff",
      borderRadius: theme.shape.borderRadius,
      borderStyle: "solid",
      borderWidth: 4,
      padding: theme.spacing(5, 2, 0),
      fontSize: 40,
      fontWeight: "bold",
      textTransform: "uppercase",
      textAlign: "center",
      cursor: "pointer",
      "& .icon": {
        fontSize: 160,
      },
      "&.small": {
        padding: theme.spacing(2.5, 1, 0),
        fontSize: 20,
        "& .icon": {
          fontSize: 80,
        },
      },
    },
    buttonSchoolUniversity: {
      borderColor: colorUniversity,
      color: colorUniversity,
      "&:hover, &.selected": {
        background: colorUniversity,
        color: theme.palette.primary.contrastText,
      },
    },
    buttonSchoolHighSchool: {
      borderColor: colorHighSchool,
      color: colorHighSchool,
      "&:hover, &.selected": {
        background: colorHighSchool,
        color: theme.palette.primary.contrastText,
      },
    },
  };
});

const HomePage = (props) => {
  const { slug, schoolType } = props;

  const classes = useStyles();

  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);

  const [categories, setCategories] = React.useState([]);
  const [courses, setCourses] = React.useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = React.useState(null);
  const [selectedSchoolType, setSelectedSchoolType] = React.useState(null);

  const dispatch = useDispatch();

  const setUrlCategorySlug = (categorySlug = null) => {
    if (!categorySlug) {
      const urlPath = "/";
      window.history.pushState({}, null, urlPath + window.location.search);
    } else {
      const urlPath = "/kategorie/" + categorySlug;
      window.history.pushState({}, null, urlPath);
    }
  };

  const setUrlSchoolType = (schoolType = null) => {
    if (!schoolType) {
      const urlPath = "/";
      window.history.pushState({}, null, urlPath);
    } else {
      const urlPath = "/" + schoolType;
      window.history.pushState({}, null, urlPath);
    }
  };

  const selectSchool = (schoolType) => {
    setUrlSchoolType(schoolType);
    setSelectedCategoryId(null);
    setSelectedSchoolType(schoolType);
    changeTheme(dispatch, schoolType);
  };

  const selectCategory = (categoryId) => {
    if (selectedCategoryId === categoryId) {
      setUrlCategorySlug(null);
      setSelectedCategoryId(null);
    } else {
      const category = categories.find((c) => {
        return c.id === categoryId;
      });
      setUrlCategorySlug(category.slug);
      // const urlPath = "/kategorie/" + category.slug;
      // window.history.pushState({}, null, urlPath);
      setSelectedCategoryId(categoryId);
      scroller.scrollTo("courses", {
        duration: 500,
        smooth: true,
        offset: -70,
      });
    }
  };

  React.useEffect(() => {
    const homepageUrl = baseUrl + "/homepage";
    const homepageRequest = axios.get(homepageUrl);
    setLoading(true);
    axios
      .all([homepageRequest])
      .then(
        axios.spread((...responses) => {
          setCategories(responses[0].data.categories);
          setCourses(responses[0].data.courses);
          if (!!slug) {
            const category = responses[0].data.categories.find((c) => {
              return c.slug === slug;
            });
            setSelectedSchoolType(category.schoolType.slug);
            setSelectedCategoryId(category.id);
            changeTheme(dispatch, category.schoolType.slug);
          } else {
            if (!!schoolType) {
              selectSchool(schoolType);
            }
          }
          setLoading(false);
        })
      )
      .catch((errors) => {
        setError(errors);
        setLoading(false);
      });
  }, []);

  const sortFn = (a, b) => {
    let aP, bP;
    if (selectedCategoryId === null) {
      aP = a.positionOnHomePage;
      bP = b.positionOnHomePage;
    } else {
      aP = a.position;
      bP = b.position;
    }
    if (aP === bP) return 0;
    if (aP < bP) return -1;
    if (aP > bP) return 1;
    return 0;
  };

  const vidRef = React.useRef(null);

  React.useEffect(() => {
    if (!loading) {
      vidRef.current.play();
    }
  }, [loading]);

  React.useEffect(() => {
    if (!slug) {
      setUrlCategorySlug(null);
      setSelectedCategoryId(null);
      setSelectedSchoolType(null);
      changeTheme(dispatch, schoolTypeXNA);
    }
  }, [slug]);

  if (loading) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      <Container>
        <Grid container spacing={2} style={{ marginTop: 8, marginBottom: 16 }}>
          <Grid item xs={12}>
            <a href={"/kreditovy-system"}>
              <video
                ref={vidRef}
                style={{ borderRadius: 10 }}
                width="100%"
                loop={true}
                autoPlay
                muted
              >
                <source src="/hp_banner_vol2.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </a>
          </Grid>

          {/*<Grid item xs={12}>*/}
          {/*  <Card*/}
          {/*    variant={"outlined"}*/}
          {/*    style={{*/}
          {/*      fontSize: 14,*/}
          {/*      marginTop: 8,*/}
          {/*      marginBottom: 8,*/}
          {/*      paddingLeft: 24,*/}
          {/*      paddingRight: 24,*/}
          {/*      paddingTop: 16,*/}
          {/*      paddingBottom: 16,*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    <Typography>*/}
          {/*      Lorem ipsum dolor sit amet, consectetuer adipiscing elit.*/}
          {/*      Aliquam erat volutpat. Mauris dictum facilisis augue. Aliquam id*/}
          {/*      dolor. Aliquam erat volutpat. Etiam dui sem, fermentum vitae,*/}
          {/*      sagittis id, malesuada in, quam. Sed ac dolor sit amet purus*/}
          {/*      malesuada congue. In enim a arcu imperdiet malesuada. Fusce*/}
          {/*      consectetuer risus a nunc. Fusce dui leo, imperdiet in, aliquam*/}
          {/*      sit amet, feugiat eu, orci.*/}
          {/*    </Typography>*/}
          {/*  </Card>*/}
          {/*</Grid>*/}

          <Grid item sm={6} xs={12}>
            <Box
              className={[
                classes.buttonSchool,
                classes.buttonSchoolUniversity,
                !!selectedSchoolType ? "small" : "",
                selectedSchoolType === schoolTypeUniversity ? "selected" : "",
              ].join(" ")}
              onClick={() => {
                selectSchool(schoolTypeUniversity);
              }}
            >
              <Box>Vysoká škola</Box>
              <Box className={"icon"}>
                <FontAwesomeIcon icon={faUniversity} />
              </Box>
            </Box>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Box
              className={[
                classes.buttonSchool,
                classes.buttonSchoolHighSchool,
                !!selectedSchoolType ? "small" : "",
                selectedSchoolType === schoolTypeHighSchool ? "selected" : "",
              ].join(" ")}
              onClick={() => {
                selectSchool(schoolTypeHighSchool);
              }}
            >
              <Box>Střední škola</Box>
              <Box className={"icon"}>
                <FontAwesomeIcon icon={faSchool} />
              </Box>
            </Box>
          </Grid>
        </Grid>

        {selectedSchoolType === schoolTypeHighSchool && (
          <>
            <Card
              variant={"outlined"}
              style={{
                fontSize: 14,
                marginBottom: 24,
                paddingLeft: 24,
                paddingRight: 24,
                paddingTop: 16,
                paddingBottom: 8,
              }}
            >
              <Typography style={{ marginBottom: 8 }}>
                Získej přístup k libovolné kapitole s různým počet videí o
                délkách od 8 do 30 minut. Ty si pak můžeš pouštět klidně
                několikrát, libovolně vracet, přeskakovat nebo pouštět zpomaleně
                či zrychleně. Získej výhodu nad prezenčním nárazovým nebo i
                pravidelným doučováním, které trvá pořád jenom omezený čas.
              </Typography>
              <Typography style={{ marginBottom: 8, fontWeight: "bold" }}>
                Studiem tě provede zkušená Claudie, která díky mnohaletým
                zkušenostem s osobním doučováním měla příležitost zjistit, co
                skutečně dělá studentům problém a na základě toho v každém
                tématu upozorňuje na časté chyby i chytáky.
              </Typography>
              <Typography>
                Aby jsi nekupoval zajíce v pytli, můžeš se podívat na některá
                videa zdarma, například úvodní videa v kapitolách:
                <ul>
                  <li>Výrazy - téma Mnohočleny;</li>
                  <li>Rovnice - téma Lineární rovnice;</li>
                  <li>Funkce - téma Úvod do funkcí;</li>
                  <li>Planimetrie - téma Úvod do čtyřúhelníků.</li>
                </ul>
              </Typography>
            </Card>
          </>
        )}

        {!!selectedSchoolType && (
          <>
            <Categories
              categories={categories}
              selectedCategoryId={selectedCategoryId}
              selectedSchoolType={selectedSchoolType}
              handleSelectCategory={selectCategory}
            />
            <Element name={"courses"}>
              {!!selectedCategoryId && (
                <>
                  <Courses
                    courses={courses
                      .filter(
                        (course) =>
                          selectedCategoryId === null ||
                          course.category.id === selectedCategoryId
                      )
                      .sort(sortFn)}
                  />
                </>
              )}
            </Element>
          </>
        )}
      </Container>
    </React.Fragment>
  );
};

export default HomePage;
