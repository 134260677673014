import React from "react";
import axios from "axios";
import { baseUrl } from "../Api/Api";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import Layout from "../Layout/Layout";
import AddCommentIcon from "@material-ui/icons/AddComment";

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: "center",
    fontSize: 36,
    fontWeight: 800,
    color: "#000",
    lineHeight: 1.15,
    [theme.breakpoints.up("md")]: {
      fontSize: 58,
    },
  },
  card: {
    borderRadius: theme.shape.borderRadius,
    background: "#fff",
    padding: theme.spacing(2),
    "& .email": {
      fontSize: 13,
    },
    "& .date": {
      fontSize: 13,
      fontStyle: "italic",
      color: "rgba(0, 0, 0, 0.5)",
    },
    "& .description": {
      marginTop: theme.spacing(2),
      fontSize: 14,
    },
  },
}));

const Review = () => {
  const classes = useStyles();

  const [reviews, setReviews] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [form, setForm] = React.useState({
    email: "",
    rating: 3,
    text: "",
  });
  const [formError, setFormError] = React.useState({});
  const [submitted, setSubmitted] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setSubmitted(false);
  };

  const handleChange = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const handleClickStar = (value) => {
    setForm({ ...form, rating: value });
  };

  const handleSubmit = () => {
    let valid = true;
    let errors = {};
    if (form.email.trim().length === 0) {
      valid = false;
      errors.email = "Vyplňte prosím jméno.";
    }
    if (form.text.trim().length === 0) {
      valid = false;
      errors.text = "Vyplňte prosím text recenze.";
    }
    setFormError(errors);
    if (valid) {
      const url = baseUrl + "/review";
      axios
        .post(url, form)
        .then((response) => {
          if (response.status === 201) {
            loadReviews();
            setForm({ email: "", text: "", rating: 3 });
            setSubmitted(true);
          }
        })
        .catch((error) => {});
    }
  };

  const loadReviews = () => {
    const url = baseUrl + "/review";
    axios
      .get(url)
      .then((response) => {
        setReviews(response.data);
      })
      .catch((error) => {});
  };

  React.useEffect(() => {
    loadReviews();
  }, []);

  return (
    <Layout title={"Recenze"}>
      <Container maxWidth={"lg"}>
        <Box mt={2} mb={4}>
          <Box mb={4} className={classes.title}>
            Recenze
          </Box>
          <Grid container spacing={2}>
            {reviews
              .sort((a, b) => {
                return b.createdAt.localeCompare(a.createdAt);
              })
              .map((r) => {
                const d = new Date(r.createdAt);
                return (
                  <>
                    <Grid item md={4} sm={6} xs={12}>
                      <Box className={classes.card}>
                        <Box display={"flex"}>
                          <Box flexGrow={1}>
                            <Box className={"email"}>{r.email}</Box>
                            <Box className={"date"}>
                              {[
                                d.getDate(),
                                d.getMonth() + 1,
                                d.getFullYear(),
                              ].join(". ")}
                            </Box>
                          </Box>
                          <Box>
                            {[...Array(r.rating).keys()].map((s) => {
                              return <StarIcon color={"primary"} />;
                            })}
                            {[...Array(5 - r.rating).keys()].map((s) => {
                              return <StarBorderIcon color={"primary"} />;
                            })}
                          </Box>
                        </Box>
                        <Box className={"description"}>{r.text}</Box>
                      </Box>
                    </Grid>
                  </>
                );
              })}
          </Grid>
          <Box mt={4} mb={4} display={"flex"} justifyContent={"center"}>
            <Button
              size={"large"}
              variant={"contained"}
              color={"primary"}
              startIcon={<AddCommentIcon />}
              onClick={handleOpen}
            >
              Přidat novou recenzi
            </Button>
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle>Přidat novou recenzi</DialogTitle>
              <DialogContent>
                {submitted && (
                  <DialogContentText>Díky za recenzi!</DialogContentText>
                )}
                {!submitted && (
                  <>
                    <Box mb={2}>
                      <TextField
                        size={"small"}
                        variant={"outlined"}
                        label={"Jméno"}
                        fullWidth
                        name={"email"}
                        value={form.email}
                        onChange={handleChange}
                        error={formError.hasOwnProperty("email")}
                        helperText={
                          formError.hasOwnProperty("email")
                            ? formError.email
                            : ""
                        }
                      />
                    </Box>
                    <Box mb={2}>
                      <TextField
                        size={"small"}
                        variant={"outlined"}
                        multiline
                        rows={4}
                        label={"Text recenze"}
                        fullWidth
                        name={"text"}
                        value={form.text}
                        onChange={handleChange}
                        error={formError.hasOwnProperty("text")}
                        helperText={
                          formError.hasOwnProperty("text") ? formError.text : ""
                        }
                      />
                    </Box>
                    <Box
                      mb={4}
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Box mr={4}>
                        <Typography variant={"body2"}>Hodnocení</Typography>
                      </Box>
                      <Box>
                        {[1, 2, 3, 4, 5].map((r) => {
                          return (
                            <React.Fragment key={r}>
                              {r <= form.rating ? (
                                <StarIcon
                                  color={"primary"}
                                  fontSize={"large"}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    handleClickStar(r);
                                  }}
                                />
                              ) : (
                                <StarBorderIcon
                                  color={"primary"}
                                  fontSize={"large"}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    handleClickStar(r);
                                  }}
                                />
                              )}
                            </React.Fragment>
                          );
                        })}
                      </Box>
                    </Box>
                  </>
                )}
              </DialogContent>
              <DialogActions>
                {submitted && (
                  <Button
                    variant={"text"}
                    color={"secondary"}
                    size={"small"}
                    onClick={handleClose}
                  >
                    Zavřít
                  </Button>
                )}
                {!submitted && (
                  <>
                    <Button
                      variant={"text"}
                      color={"secondary"}
                      size={"small"}
                      onClick={handleClose}
                    >
                      Zrušit
                    </Button>
                    <Button
                      variant={"contained"}
                      color={"primary"}
                      onClick={handleSubmit}
                    >
                      Přidat recenzi
                    </Button>
                  </>
                )}
              </DialogActions>
            </Dialog>
          </Box>
        </Box>
      </Container>
    </Layout>
  );
};

export default Review;
