import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";

const useStyles = makeStyles((theme) => ({
  categories: {
    marginBottom: theme.spacing(1),
  },
  card: {
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    "&:hover, &.active": {
      cursor: "pointer",
      backgroundColor: theme.palette.secondary.light,
    },
  },
}));

const Categories = (props) => {
  const classes = useStyles();

  const {
    categories,
    selectedCategoryId,
    handleSelectCategory,
    selectedSchoolType,
  } = props;

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="stretch"
      spacing={2}
      className={classes.categories}
    >
      {categories
        .filter((category) => {
          return category.schoolType.slug === selectedSchoolType;
        })
        .map((category) => {
          return (
            <Grid item xs={12} sm={6} md={3} key={categories.indexOf(category)}>
              <Card
                className={
                  classes.card +
                  " " +
                  (category.id === selectedCategoryId ? "active" : "")
                }
                onClick={() => {
                  handleSelectCategory(category.id);
                }}
              >
                <Typography variant={"subtitle1"}>{category.title}</Typography>
                <Typography variant={"caption"}>{category.subtitle}</Typography>
              </Card>
            </Grid>
          );
        })}
    </Grid>
  );
};

export default Categories;
