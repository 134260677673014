import { BrowserRouter, Route, Switch } from "react-router-dom";

import React from "react";
import Home_Page from "./pages/Home_Page";
import Course_Page from "./pages/Course_Page";
import AboutUsView from "./views/AboutUsView";
import RecenzeView from "./views/RecenzeView";
import {
  schoolTypeHighSchool,
  schoolTypeUniversity,
} from "./constants/constants";
import CreditSystemPage from "./components/CreditSystem/CreditSystemPage";
import { useCookies } from "react-cookie";
import FaqPage from "./components/FaqPage/FaqPage";

const Routes = (props) => {
  const [cookies, setCookie, removeCookie] = useCookies();

  React.useEffect(() => {
    if (window.location.search !== "") {
      const urlParams = new URLSearchParams(window.location.search);
      const s = urlParams.get("s");
      setCookie("shareLink", s, { path: "/" });
    }
  }, [window.location.search]);

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/o-nas" component={AboutUsView} />
        <Route exact path="/recenze" component={RecenzeView} />
        <Route exact path="/faq" component={FaqPage} />
        <Route exact path="/kreditovy-system" component={CreditSystemPage} />
        <Route
          exact
          path="/"
          render={(props) => <Home_Page {...props} schoolType={null} />}
        />
        <Route
          exact
          path={"/" + schoolTypeUniversity}
          render={(props) => (
            <Home_Page {...props} schoolType={schoolTypeUniversity} />
          )}
        />
        <Route
          exact
          path={"/" + schoolTypeHighSchool}
          render={(props) => (
            <Home_Page {...props} schoolType={schoolTypeHighSchool} />
          )}
        />
        <Route path="/kategorie/:slug" component={Home_Page} />
        <Route path="/kurz/:slug" component={Course_Page} />
        {/*<Route path="*" component={Error404} />*/}
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
