export const formatPrice = (value) => {
  if (value * 1 === 0) {
    return "zdarma";
  }
  return value + " Kč";
};

export const formatChipValue = (courseType, chipType, value) => {
  if (chipType === "count") {
    return value + " " + (courseType === "kurz" ? "lekcí" : "otázek");
  } else {
    const hours = Math.floor(value / 60 / 60);
    const minutes = Math.floor((value - hours * 60 * 60) / 60);
    return hours + " h " + minutes + " min";
  }
};

export const truncateText = (text) => {
  const trimmed = text.replace(/^(.{30}[^\s]*).*/, "$1");
  return trimmed + (trimmed.substr(trimmed.length - 1) === "." ? ".." : "...");
};

export const compareArrays = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false;
  }
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }
  return true;
};

export const shuffleArray = (array) => {
  let currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
};
