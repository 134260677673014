import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  container: {
    textAlign: "center",
    paddingTop: theme.spacing(10),
  },
}));

const Loading = (props) => {
  const classes = useStyle();

  return (
    <Container className={classes.container}>
      <CircularProgress />
    </Container>
  );
};

export default Loading;
