import React from "react";
import Layout from "../components/Layout/Layout";
import HomePage from "../components/HomePage/HomePage";

const Home_Page = (props) => {
  const { schoolType = null } = props;

  const { slug } = props.match.params;

  return (
    <Layout title={"VideoNalejvárny"}>
      <HomePage slug={slug} schoolType={schoolType} />
    </Layout>
  );
};

export default Home_Page;
