import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Container,
  IconButton,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import Layout from "../Layout/Layout";
import FormDialogLogIn from "../FormDialogLogIn/FormDialogLogIn";
import { FileCopy } from "@material-ui/icons";
import { useCookies } from "react-cookie";
import axios from "axios";
import { baseUrl } from "../Api/Api";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: "center",
    fontSize: 36,
    fontWeight: 800,
    color: "#000",
    lineHeight: 1.15,
    [theme.breakpoints.up("md")]: {
      fontSize: 58,
    },
  },
  subtitle: {
    color: theme.palette.primary.main,
    fontWeight: 800,
  },
}));

const CollapseCard = (props) => {
  const { title, children } = props;

  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen((prev) => !prev);
  };

  return (
    <Card style={{ marginTop: 24, marginBottom: 24 }}>
      <Box
        style={{
          paddingLeft: 24,
          paddingRight: 24,
          paddingTop: 24,
          paddingBottom: 24,
          fontSize: 18,
          fontWeight: 600,
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
        }}
        onClick={() => {
          toggleOpen();
        }}
      >
        <Box sx={{ flexGrow: 1 }}>{title}</Box>
        <DoubleArrowIcon
          color={"primary"}
          style={{
            transform: open ? "rotate(-90deg)" : "rotate(90deg)",
            transition: "all 500ms",
          }}
        />
      </Box>
      <Collapse in={open}>
        <CardContent
          style={{
            paddingLeft: 24,
            paddingRight: 24,
            paddingBottom: 24,
            paddingTop: 0,
          }}
        >
          {children}
        </CardContent>
      </Collapse>
    </Card>
  );
};

const CreditSystemPage = () => {
  const classes = useStyles();

  const shareLinkUrl = "https://videonalejvarny.cz?s=";

  const [cookies, setCookie, removeCookie] = useCookies();
  const [shareLink, setShareLink] = React.useState("");
  const [shareLinkStatus, setShareLinkStatus] = React.useState("");

  const copyTextToClipboard = async (text) => {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  };

  const handleCopy = () => {
    copyTextToClipboard(shareLink)
      .then(() => {
        // If successful, update the isCopied state value
        setShareLinkStatus(
          "Odkaz zkopírován do schránky. Nyní jej můžete sdílet mezi svými kamarády."
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    axios({
      method: "GET",
      url: baseUrl + "/credit/me",
      headers: {
        Authorization: cookies.tokenComposite || "",
        "Content-Type": "multipart/form-data",
      },
    })
      .then(function (response) {
        if (response.status === 200 && response.data) {
          setShareLink(shareLinkUrl + response.data.shareLink);
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  }, [cookies]);

  return (
    <Layout title={"Kreditový systém"}>
      <Container maxWidth={"lg"}>
        <Box mt={2} mb={4}>
          <Box mb={4} className={classes.title}>
            Kreditový systém
          </Box>
          <Typography variant={"h5"} className={classes.subtitle}>
            Jak to funguje?
          </Typography>
          <Typography>
            Pro fungování tohoto hustokrutopřísného udělátka si na této stránce
            vygeneruješ link a nasdílíš ho tam, kde je zrovna potřeba, třeba
            svým kamarádům, spolužákům nebo prarodičům.
          </Typography>
          {shareLink === "" && (
            <>
              <Typography>
                Pro získání svého sharelinku je potřeba se přihlásit.
              </Typography>
              <FormDialogLogIn
                showCredits={false}
                showLoginButton={true}
                showAccount={false}
              />
            </>
          )}
          {shareLink !== "" && (
            <>
              <Card style={{ marginTop: 24, marginBottom: 24 }}>
                <CardContent style={{ padding: 24 }}>
                  <Box style={{ display: "flex" }}>
                    <Box style={{ flexGrow: 1 }}>
                      <TextField
                        label={"Odkaz ke sdílení"}
                        value={shareLink}
                        fullWidth={true}
                        variant={"outlined"}
                        color={"primary"}
                        disabled={false}
                      />
                    </Box>
                    <Box style={{ paddingLeft: 24 }}>
                      <IconButton
                        onClick={() => {
                          handleCopy();
                        }}
                      >
                        <FileCopy />
                      </IconButton>
                    </Box>
                  </Box>
                  {shareLinkStatus !== "" && (
                    <Box style={{ paddingTop: 12 }}>{shareLinkStatus}</Box>
                  )}
                </CardContent>
              </Card>
              <Typography>
                <strong>A teď už neváhej a pošli to dál!</strong>
              </Typography>
            </>
          )}
          <Typography
            variant={"h5"}
            className={classes.subtitle}
            style={{ marginTop: 32 }}
          >
            Pro hlubináče!
          </Typography>
          <CollapseCard title={"Příklad, jak to celé funguje"}>
            Řekněme, že si Ježíš vygeneruje svůj Svatý link a nasdílí ho někam
            dál (FB skupina, messenger apod.). Jidáš, Matouš a Lukáš se pomocí
            linku dostanou na web a nakoupí za 1 000 Kč (Jidáš), 550 Kč (Matouš)
            a 500 Kč (Lukáš). Ježíš díky těmto nákupům získá na svůj účet 100 +
            55 + 50 kreditů, které pak může utratit za libovolný počet kurzů.
          </CollapseCard>
          <CollapseCard title={"Jaká je hodnota jednoho kreditu?"}>
            1 kredit = 1 Kč sleva
          </CollapseCard>
          <CollapseCard title={"Na co mohu kredity využít?"}>
            Kredity lze využít jako slevu pro nákup dalších kurzů. Pokud kurz
            stojí 1 000 Kč a ty máš 1 000 kreditů (nebo více), je možnost
            pořídit kurz zcela zdarma. Pokud máš 999 kreditů, je nutno za kurz
            zaplatit minimálně 30 Kč, protože naše platební brána nižší částku
            jako platbu nepřijímá. Zbylých 970 Kč doplatíš z kreditů.
          </CollapseCard>
          <CollapseCard title={"Kolik lidí může skrze můj link nakupovat?"}>
            Skrze tvůj link může nakupovat libovolný počet uživatelů (třeba 1
            000, jestli si dobrý influencer) a je zde možnost získat slušné
            slevy. Existují studenti, kteří nás již doporučili tolikrát, že by
            si s množstvím kreditů, které by za své doporučeni dostali,
            vystačili na všechny kurzy Videonalejváren potřebné pro dostudování
            školy.
          </CollapseCard>
          <CollapseCard title={"Je sharelink permanentní?"}>
            Tvůj link zůstává pořád stejný, nemění se. Když ho někam nasdílíš,
            uživatelé skrz nej můžou nakupovat měsíce, nebo roky a tobě budou
            generovat kredity (a to už možná dřív doděláš školu).
          </CollapseCard>
        </Box>
      </Container>
    </Layout>
  );
};

export default CreditSystemPage;
