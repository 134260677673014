import Paper from "@material-ui/core/Paper";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Comment from "../Comment/Comment";

const useStyle = makeStyles((theme) => ({
  paper: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1, 2, 2, 2),
  },
}));

const PaperComments = (props) => {
  const { course, id, title } = props;

  // const [config, setConfig] = React.useState(null);

  const classes = useStyle();

  // React.useEffect(() => {
  //   if (course && id && title) {
  //     setConfig({
  //       url: window.location.href.split("?")[0] + "?id=" + id,
  //       identifier: course.slug + "-" + id + "-0", //hack? https://github.com/disqus/disqus-react/issues/83
  //       title: course.title + " / " + title,
  //       language: "cz_CS",
  //     });
  //   }
  // }, [id]);
  //
  // if (config === null) {
  //   return "";
  // }

  return (
    <Paper className={classes.paper}>
      <Comment courseId={course.id} />
      {/*<DiscussionEmbed*/}
      {/*  shortname={process.env.REACT_APP_DISQUS_SHORTNAME}*/}
      {/*  config={config}*/}
      {/*/>*/}
    </Paper>
  );
};

export default PaperComments;
