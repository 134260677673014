import React from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";
import Grid from "@material-ui/core/Grid";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { compareArrays, shuffleArray, truncateText } from "../Utils/Utils";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import PaperComments from "./PaperComments";
import PaperTitle from "./PaperTitle";

const ContentPurchased = (props) => {
  const {
    classes,
    course,
    question,
    checked,
    check,
    selectedAnswers,
    toggleAnswer,
    answers,
  } = props;

  return (
    <Paper className={classes.paper}>
      <div className={classes.paperBody}>
        <PaperTitle
          lessonTitle={
            course.questions.indexOf(question) +
            1 +
            " / " +
            course.questions.length
          }
          categoryTitle={course.category.title}
          courseTitle={course.title}
        />
        <Typography color={"textSecondary"} className={classes.description}>
          {question.text}
        </Typography>
      </div>
      <Divider />
      <Box
        className={getClassNameForQuestion(
          checked,
          answers,
          selectedAnswers,
          classes
        )}
        style={{ position: "relative" }}
      >
        {checked && (
          <Chip
            className={getClassNameForQuestionInfo(
              checked,
              answers,
              selectedAnswers,
              classes
            )}
            icon={
              isAnsweredCorrectly(answers, selectedAnswers) ? (
                <CheckCircleIcon />
              ) : (
                <CancelIcon />
              )
            }
            label={
              isAnsweredCorrectly(answers, selectedAnswers)
                ? "Správně"
                : "Špatně"
            }
          />
        )}
        <List>
          {answers &&
            answers.map((answer) => {
              return (
                <ListItem
                  key={answers.indexOf(answer)}
                  button={!checked}
                  onClick={() => {
                    toggleAnswer(answers.indexOf(answer));
                  }}
                >
                  <ListItemIcon
                    className={getClassNameForAnswer(
                      checked,
                      answer.correct,
                      classes
                    )}
                  >
                    {selectedAnswers.includes(answers.indexOf(answer)) && (
                      <CheckBoxIcon />
                    )}
                    {!selectedAnswers.includes(answers.indexOf(answer)) && (
                      <CheckBoxOutlineBlankIcon />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    className={getClassNameForAnswer(
                      checked,
                      answer.correct,
                      classes
                    )}
                    primary={answer.text}
                  />
                </ListItem>
              );
            })}
        </List>
      </Box>
      <Divider />
      <div className={classes.paperBody} style={{ textAlign: "center" }}>
        <Button
          size={"large"}
          disabled={checked}
          variant={"contained"}
          color={"primary"}
          onClick={() => {
            check();
          }}
        >
          <CheckIcon /> Zkontrolovat
        </Button>
      </div>
    </Paper>
  );
};

const PrevNextButtons = (props) => {
  const { classes, course, question, changeQuestion } = props;

  return (
    <Grid container className={classes.buttons}>
      <Grid item xs>
        <Button
          size="small"
          disabled={course.questions[0] === question}
          variant={"contained"}
          color={"primary"}
          onClick={() => {
            changeQuestion(
              course.questions[course.questions.indexOf(question) - 1]
            );
          }}
        >
          <KeyboardArrowLeft /> Předchozí otázka
        </Button>
      </Grid>
      <Grid item>
        <Button
          size="small"
          disabled={course.questions[course.questions.length - 1] === question}
          variant={"contained"}
          color={"primary"}
          onClick={() => {
            changeQuestion(
              course.questions[course.questions.indexOf(question) + 1]
            );
          }}
        >
          Další otázka <KeyboardArrowRight />
        </Button>
      </Grid>
    </Grid>
  );
};

const isAnsweredCorrectly = (answers, selectedAnswer) => {
  const correctAnswers = [];

  answers.map((a) => {
    if (a.correct) {
      correctAnswers.push(answers.indexOf(a));
    }
  });

  return compareArrays(selectedAnswer.sort(), correctAnswers.sort());
};

const getClassNameForQuestionInfo = (
  checked,
  answers,
  selectedAnswer,
  classes
) => {
  if (checked) {
    if (isAnsweredCorrectly(answers, selectedAnswer)) {
      return classes.questionInfoCorrect;
    }
    return classes.questionInfoBad;
  }
  return "";
};

const getClassNameForQuestion = (checked, answers, selectedAnswer, classes) => {
  if (checked) {
    if (isAnsweredCorrectly(answers, selectedAnswer)) {
      return classes.questionCorrect;
    }
    return classes.questionBad;
  }
  return "";
};

const getClassNameForAnswer = (checked, correctAnswer, classes) => {
  if (checked) {
    if (correctAnswer) {
      return classes.answerCorrect;
    }
    return classes.answerBad;
  }
  return "";
};

const Question = (props) => {
  const { question, course, classes, changeQuestion, makeAnswer } = props;

  const [loading, setLoading] = React.useState(true);
  const [checked, setChecked] = React.useState(false);
  const [selectedAnswers, setSelectedAnswers] = React.useState([]);
  const [answers, setAnswers] = React.useState([]);

  const toggleAnswer = (id) => {
    if (!checked) {
      if (selectedAnswers.includes(id)) {
        setSelectedAnswers(selectedAnswers.filter((a) => a !== id));
      } else {
        setSelectedAnswers(selectedAnswers.concat(id));
      }
    }
  };

  const check = () => {
    setChecked(true);
    makeAnswer(isAnsweredCorrectly(answers, selectedAnswers));
  };

  React.useEffect(() => {
    setLoading(true);
    if (question && question.answers) {
      setChecked(false);
      setSelectedAnswers([]);
      setAnswers(shuffleArray(question.answers));
      setLoading(false);
    }
  }, [question]);

  if (loading) {
    return "";
  }

  return (
    <React.Fragment>
      <ContentPurchased
        classes={classes}
        course={course}
        question={question}
        toggleAnswer={toggleAnswer}
        check={check}
        checked={checked}
        selectedAnswers={selectedAnswers}
        answers={answers}
      />
      <PrevNextButtons
        classes={classes}
        course={course}
        question={question}
        changeQuestion={changeQuestion}
      />
      <PaperComments
        course={course}
        id={truncateText(question.text)}
        title={truncateText(question.text)}
      />
    </React.Fragment>
  );
};

export default Question;
