import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import GetAppIcon from "@material-ui/icons/GetApp";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Vimeo from "@u-wave/react-vimeo";
import { Box } from "@material-ui/core";
import PaperTitle from "./PaperTitle";
import PaperComments from "./PaperComments";
import { baseUrl } from "../Api/Api";
import FormDialogPayment from "../FormDialogPayment/FormDialogPayment";
import axios from "axios";
import { useCookies } from "react-cookie";

const ContentNotPurchased = (props) => {
  const { classes, lesson, course, unlockCourse } = props;

  return (
    <Paper className={classes.paper}>
      <Box className={classes.paperBody}>
        <PaperTitle
          lessonTitle={lesson.code + " " + lesson.title}
          categoryTitle={course.category.title}
          courseTitle={course.title}
        />
        <Box className={classes.buyInfo}>
          <Typography variant={"h4"} className={classes.buyInfoText}>
            Tato lekce a video patří do placené části kurzu.
          </Typography>
          <FormDialogPayment course={course} unlockCourse={unlockCourse} />
        </Box>
      </Box>
    </Paper>
  );
};

const PrevNextButtons = (props) => {
  const { classes, course, lesson, changeLesson } = props;

  if (!(course.lessons && course.lessons.length > 1)) {
    return "";
  }

  return (
    <Grid container alignItems={"center"} className={classes.buttons}>
      <Grid item xs>
        <Button
          size="small"
          disabled={course.lessons[0].id === lesson.id}
          variant={"contained"}
          color={"primary"}
          onClick={() => {
            changeLesson(course.lessons[course.lessons.indexOf(lesson) - 1]);
          }}
        >
          <KeyboardArrowLeft /> Předchozí lekce
        </Button>
      </Grid>
      <Grid item>
        <Button
          size="small"
          disabled={course.lessons[course.lessons.length - 1].id === lesson.id}
          variant={"contained"}
          color={"primary"}
          onClick={() => {
            changeLesson(course.lessons[course.lessons.indexOf(lesson) + 1]);
          }}
        >
          Další lekce <KeyboardArrowRight />
        </Button>
      </Grid>
    </Grid>
  );
};

const ContentPurchased = (props) => {
  const { classes, lesson, updateVideoProgress } = props;

  if (lesson.hasOwnProperty("videoBunnyUrl") && !!lesson.videoBunnyUrl) {
    return (
      <div
        style={{ position: "relative", paddingTop: "56.25%", marginBottom: 16 }}
      >
        <iframe
          src={lesson.videoBunnyUrl}
          loading="lazy"
          style={{
            border: "none",
            position: "absolute",
            top: 0,
            height: "100%",
            width: "100%",
          }}
          allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
          allowFullScreen="true"
        />
      </div>
    );
  }

  if (lesson.hasOwnProperty("videoUrl") && !!lesson.videoUrl) {
    return (
      <Vimeo
        video={lesson.videoUrl}
        responsive={true}
        showPortrait={false}
        showByline={false}
        showTitle={false}
        autoplay={false}
        className={classes.vimeo}
        onPlay={() => {
          updateVideoProgress("status", "play");
        }}
        onPause={() => {
          updateVideoProgress("status", "pause");
        }}
        onEnd={() => {
          updateVideoProgress("status", "end");
        }}
        onTimeUpdate={(event) => {
          updateVideoProgress("progress", event);
        }}
      />
    );
  }

  return <></>;
};

const Description = (props) => {
  const { classes, lesson, course } = props;

  return (
    <Paper className={classes.paper}>
      <div className={classes.paperBody}>
        <PaperTitle
          lessonTitle={lesson.code + " " + lesson.title}
          categoryTitle={course.category.title}
          courseTitle={course.title}
        />
        <Typography color={"textSecondary"} className={classes.description}>
          {lesson.description}
        </Typography>
      </div>
      {lesson.files && lesson.files.length > 0 && (
        <React.Fragment>
          <Divider />
          <div className={classes.paperBody}>
            <div className={classes.downloads}>
              {lesson.files.map((file) => {
                return (
                  <a href={baseUrl + "/files/" + file.slug}>
                    <Button
                      key={lesson.files.indexOf(file)}
                      size="small"
                      variant={"outlined"}
                      color={"primary"}
                      startIcon={<GetAppIcon />}
                    >
                      {file.title}
                    </Button>
                  </a>
                );
              })}
            </div>
          </div>
        </React.Fragment>
      )}
    </Paper>
  );
};

const Lesson = (props) => {
  const [cookies, setCookie, removeCookie] = useCookies();

  const {
    classes,
    lesson,
    course,
    changeLesson,
    updateVideoProgress,
    unlockCourse,
  } = props;

  const monitor = (lesson) => {
    const data = {
      lessonId: lesson.id,
      email: "token" in cookies ? cookies["token"] : null,
    };
    axios({
      method: "post",
      url: baseUrl + "/monitor/lesson",
      data: data,
    }).then((response) => {});
  };

  React.useEffect(() => {
    if (lesson) {
      monitor(lesson);
    }
  }, [lesson]);

  if (lesson.purchased) {
    return (
      <React.Fragment>
        <ContentPurchased
          lesson={lesson}
          classes={classes}
          updateVideoProgress={updateVideoProgress}
          course={course}
        />
        <PrevNextButtons
          lesson={lesson}
          classes={classes}
          changeLesson={changeLesson}
          course={course}
        />
        <Description classes={classes} course={course} lesson={lesson} />
        <PaperComments course={course} id={lesson.id} title={lesson.title} />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <ContentNotPurchased
        lesson={lesson}
        classes={classes}
        updateVideoProgress={updateVideoProgress}
        course={course}
        unlockCourse={unlockCourse}
      />
      <PrevNextButtons
        lesson={lesson}
        classes={classes}
        changeLesson={changeLesson}
        course={course}
      />
    </React.Fragment>
  );
};

export default Lesson;
