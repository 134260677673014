import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { baseUrl } from "../Api/Api";
import Alert from "@material-ui/lab/Alert";
import { useCookies } from "react-cookie";

const useStyles = makeStyles((theme) => {
  return {
    dialogBody: {
      padding: theme.spacing(2),
    },
    marginBottom: {
      marginBottom: theme.spacing(2),
    },
    credits: {
      color: theme.palette.primary.main,
    },
  };
});

const FormDialogRegister = (props) => {
  const { showRegisterButton = true } = props;

  const classes = useStyles();

  //const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);

  const [cookies, setCookie, removeCookie] = useCookies();
  const [email, setEmail] = React.useState("");

  const [form, setForm] = React.useState({
    email: "",
    firstname: "",
    lastname: "",
    street: "",
    streetNumber: "",
    city: "",
    postcode: "",
  });
  const [formError, setFormError] = React.useState({
    formValid: true,
    email: "",
    firstname: "",
    lastname: "",
    street: "",
    streetNumber: "",
    city: "",
    postcode: "",
  });
  const [registered, setRegistered] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdate = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const submitForm = () => {
    const fe = {
      formValid: true,
      email: "",
      firstname: "",
      lastname: "",
      street: "",
      streetNumber: "",
      city: "",
      postcode: "",
    };
    setFormError(fe);
    if (!validateEmail(form.email)) {
      fe.formValid = false;
      fe.email = "E-mail nemá správný formát.";
    }
    Object.keys(form).forEach((key) => {
      if (form[key] === "" || form[key].length === 0) {
        fe.formValid = false;
        fe[key] = "Pole je povinné.";
      }
    });

    if (fe.formValid) {
      setLoading(true);
      axios({
        method: "post",
        url: baseUrl + "/register",
        data: form,
      })
        .then(function (response) {
          setRegistered(true);
        })
        .catch(function (response) {})
        .finally(() => {
          setLoading(false);
        });
    } else {
      setFormError(fe);
    }
  };

  React.useEffect(() => {
    if ("token" in cookies) {
      setEmail(cookies["token"]);
    }
  }, [cookies]);

  if (email !== "") {
    return <></>;
  }

  return (
    <React.Fragment>
      {showRegisterButton && (
        <Button
          style={{ marginLeft: 16 }}
          variant="contained"
          color="primary"
          onClick={handleClickOpen}
        >
          Registrovat se
        </Button>
      )}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle className={classes.dialogBody}>Registrovat se</DialogTitle>
        <DialogContent className={classes.dialogBody}>
          {!registered && (
            <>
              <DialogContentText>
                Pro používání Videonalejvárny je potřeba se registrovat. Vyplňte
                prosím všechna pole níže. Bude vám vytvořen účet a heslo vám
                pošleme na e-mail.
              </DialogContentText>
              <TextField
                className={classes.marginBottom}
                autoFocus
                type="email"
                variant={"outlined"}
                fullWidth
                onChange={handleUpdate}
                label="E-mail"
                name={"email"}
                value={form.email}
                error={formError.email !== ""}
                helperText={formError.email}
              />
              <TextField
                className={classes.marginBottom}
                variant={"outlined"}
                fullWidth
                onChange={handleUpdate}
                label="Křestní jméno"
                name={"firstname"}
                value={form.firstname}
                error={formError.firstname !== ""}
                helperText={formError.firstname}
              />
              <TextField
                className={classes.marginBottom}
                variant={"outlined"}
                fullWidth
                onChange={handleUpdate}
                label="Příjmení"
                name={"lastname"}
                value={form.lastname}
                error={formError.lastname !== ""}
                helperText={formError.lastname}
              />
              <TextField
                className={classes.marginBottom}
                variant={"outlined"}
                fullWidth
                onChange={handleUpdate}
                label="Ulice"
                name={"street"}
                value={form.street}
                error={formError.street !== ""}
                helperText={formError.street}
              />
              <TextField
                className={classes.marginBottom}
                variant={"outlined"}
                fullWidth
                onChange={handleUpdate}
                label="číslo popisné"
                name={"streetNumber"}
                value={form.streetNumber}
                error={formError.streetNumber !== ""}
                helperText={formError.streetNumber}
              />
              <TextField
                className={classes.marginBottom}
                variant={"outlined"}
                fullWidth
                onChange={handleUpdate}
                label="Město"
                name={"city"}
                value={form.city}
                error={formError.city !== ""}
                helperText={formError.city}
              />
              <TextField
                className={classes.marginBottom}
                variant={"outlined"}
                fullWidth
                onChange={handleUpdate}
                label="PSČ"
                name={"postcode"}
                value={form.postcode}
                error={formError.postcode !== ""}
                helperText={formError.postcode}
              />
            </>
          )}
          {registered && (
            <>
              <DialogContentText>
                Děkujeme za registraci. Přihlašovací údaje jsme vám poslali na
                zadaný e-mail.
              </DialogContentText>
            </>
          )}
        </DialogContent>
        <Divider />
        <DialogActions className={classes.dialogBody}>
          <Button onClick={handleClose} color="primary">
            Zavřít
          </Button>
          {!registered && (
            <Button
              disabled={loading}
              onClick={submitForm}
              color="primary"
              variant={"contained"}
            >
              Registrovat se
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default FormDialogRegister;
