import React from "react";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Paper from "@material-ui/core/Paper";
import ListSubheader from "@material-ui/core/ListSubheader";
import LockIcon from "@material-ui/icons/Lock";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import PauseCircleOutlineIcon from "@material-ui/icons/PauseCircleOutline";
import AdjustIcon from "@material-ui/icons/Adjust";
import { getTheme } from "../Theme/Theme";
import Lesson from "./Lesson";
import { Box } from "@material-ui/core";
import { schoolTypeUniversity } from "../../constants/constants";
import { useSelector } from "react-redux";

const getIconForLesson = (purchased, active, action) => {
  if (purchased) {
    if (active) {
      switch (action) {
        case "init":
          return <AdjustIcon />;
        case "play":
          return <PlayCircleOutlineIcon />;
        case "pause":
          return <PauseCircleOutlineIcon />;
        default:
          return <AdjustIcon />;
      }
    }
    return <RadioButtonUncheckedIcon />;
  }
  return <LockIcon />;
};

const getProgressBar = (active, percent) => {
  const theme = getTheme(schoolTypeUniversity);
  if (active) {
    return (
      <Box
        style={{
          height: 2,
          width: "100%",
          background:
            "linear-gradient(90deg, " +
            theme.palette.primary.main +
            " " +
            percent * 100 +
            "%, " +
            theme.palette.secondary.light +
            " " +
            percent * 100 +
            "%)",
        }}
      />
    );
  } else {
    return (
      <Box
        style={{
          height: 2,
          width: "100%",
          background: theme.palette.secondary.light,
        }}
      />
    );
  }
};

const LessonItem = (props) => {
  const { classes, lesson, active, changeLesson, videoProgress } = props;

  const theme = useSelector((state) => state.app.theme);

  return (
    <React.Fragment>
      <ListItem
        button
        className={classes.listItem + " " + (active ? classes.selected : null)}
        onClick={() => {
          changeLesson(lesson);
        }}
      >
        <ListItemIcon style={{ color: theme.palette.primary.main }}>
          {getIconForLesson(lesson.purchased, active, videoProgress.status)}
        </ListItemIcon>
        <ListItemText primary={lesson.code + " " + lesson.title} />
      </ListItem>
      {getProgressBar(active, videoProgress.progress.percent)}
    </React.Fragment>
  );
};

const Video = (props) => {
  const initialVideoProgress = {
    status: "init",
    progress: {
      seconds: 0,
      percent: 0,
      duration: 0,
    },
  };
  const theme = useSelector((state) => state.app.theme);

  const { classes, course, unlockCourse } = props;

  const [lesson, setLesson] = React.useState(false);
  const [videoProgress, setVideoProgress] =
    React.useState(initialVideoProgress);

  const changeLesson = (l) => {
    setVideoProgress(initialVideoProgress);
    setLesson(l);
  };

  const updateVideoProgress = (name, value) => {
    //pokud volaný signál mění status AND tento signál není PAUSE následující po INIT (tzn. současná hodnota stavu je INIT)
    //nebo volaný signál mění progress AND současná hodnota stavu není INIT
    if (
      (name === "status" &&
        !(value === "pause" && videoProgress.status === "init")) ||
      (name === "progress" && !(videoProgress.status === "init"))
    ) {
      setVideoProgress({ ...videoProgress, [name]: value });
    }
  };

  const sortFn = (a, b) => {
    let aP, bP;
    aP = a.position;
    bP = b.position;
    if (aP === bP) return 0;
    if (aP < bP) return -1;
    if (aP > bP) return 1;
    return 0;
  };

  React.useEffect(() => {
    if (course.lessons && course.lessons.length > 0) {
      if (lesson !== false) {
        setLesson(course.lessons.find((l) => l.id === lesson.id));
      } else {
        const url = new URL(window.location.href);
        const id = url.searchParams.get("id");
        if (id) {
          setLesson(course.lessons.find((l) => l.id * 1 === id * 1));
        } else {
          setLesson(course.lessons[0]);
        }
      }
    }
  }, [course]);

  return (
    <Grid container direction={"row"} spacing={2}>
      <Grid item xs={12} sm={12} md={8}>
        <Lesson
          classes={classes}
          lesson={lesson}
          course={course}
          changeLesson={changeLesson}
          updateVideoProgress={updateVideoProgress}
          unlockCourse={unlockCourse}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        {course.lessons && (
          <React.Fragment>
            <Paper className={classes.lessons}>
              <List
                component="nav"
                subheader={
                  <ListSubheader
                    component="div"
                    style={{ color: theme.palette.primary.main }}
                  >
                    Lekce kurzu
                  </ListSubheader>
                }
              >
                {course.lessons.sort(sortFn).map((l) => {
                  return (
                    <LessonItem
                      key={course.lessons.indexOf(l)}
                      lesson={l}
                      classes={classes}
                      active={l.id === lesson.id}
                      changeLesson={changeLesson}
                      videoProgress={videoProgress}
                    />
                  );
                })}
              </List>
            </Paper>
          </React.Fragment>
        )}
      </Grid>
    </Grid>
  );
};

export default Video;
