import React from "react";
import axios from "axios";
import { baseUrl } from "../Api/Api";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  makeStyles,
  TextField,
} from "@material-ui/core";
import AddCommentIcon from "@material-ui/icons/AddComment";

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: "center",
    fontSize: 36,
    fontWeight: 800,
    color: "#000",
    lineHeight: 1.15,
    [theme.breakpoints.up("md")]: {
      fontSize: 58,
    },
  },
  card: {
    borderRadius: 0,
    background: "#fff",
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(2),
    borderBottom: "solid 1px rgba(0, 0, 0, 0.12)",
    "& .email": {
      fontSize: 13,
      fontWeight: "bold",
    },
    "& .date": {
      fontSize: 13,
      fontStyle: "italic",
      color: "rgba(0, 0, 0, 0.5)",
    },
    "& .description": {
      marginTop: theme.spacing(1),
      fontSize: 14,
    },
  },
}));

const Comment = (props) => {
  const { courseId } = props;

  const classes = useStyles();

  const [comments, setComments] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [form, setForm] = React.useState({
    email: "",
    text: "",
    courseId,
  });
  const [formError, setFormError] = React.useState({});
  const [submitted, setSubmitted] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setSubmitted(false);
  };

  const handleChange = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const handleSubmit = () => {
    let valid = true;
    let errors = {};
    if (form.email.trim().length === 0) {
      valid = false;
      errors.email = "Vyplňte prosím jméno.";
    }
    if (form.text.trim().length === 0) {
      valid = false;
      errors.text = "Vyplňte prosím text komentáře.";
    }
    setFormError(errors);
    if (valid) {
      const url = baseUrl + "/course/" + courseId + "/comment";
      const data = { ...form, courseId };
      axios
        .post(url, data)
        .then((response) => {
          if (response.status === 201) {
            loadComments();
            setForm({ email: "", text: "", courseId });
            setSubmitted(true);
          }
        })
        .catch((error) => {});
    }
  };

  const loadComments = () => {
    const url = baseUrl + "/course/" + courseId + "/comment";
    axios
      .get(url)
      .then((response) => {
        setComments(response.data);
      })
      .catch((error) => {});
  };

  React.useEffect(() => {
    loadComments();
  }, []);

  return (
    <>
      <Box mt={2} mb={2}>
        {/*<Box mb={4} className={classes.title}>*/}
        {/*  Komentáře*/}
        {/*</Box>*/}
        <Grid container spacing={2}>
          {comments
            .sort((a, b) => {
              return b.createdAt.localeCompare(a.createdAt);
            })
            .map((r) => {
              const d = new Date(r.createdAt);
              return (
                <>
                  <Grid item xs={12}>
                    <Box className={classes.card}>
                      <Box display={"flex"}>
                        <Box
                          flexGrow={1}
                          display={"flex"}
                          justifyContent={"space-between"}
                        >
                          <Box className={"email"}>{r.email}</Box>
                          <Box className={"date"}>
                            {[
                              d.getDate(),
                              d.getMonth() + 1,
                              d.getFullYear(),
                            ].join(". ")}
                          </Box>
                        </Box>
                      </Box>
                      <Box className={"description"}>{r.text}</Box>
                    </Box>
                  </Grid>
                </>
              );
            })}
        </Grid>
        <Box mt={4} mb={4} display={"flex"} justifyContent={"center"}>
          <Button
            size={"large"}
            variant={"contained"}
            color={"primary"}
            startIcon={<AddCommentIcon />}
            onClick={handleOpen}
          >
            Přidat nový komentář
          </Button>
          <Dialog
            open={open}
            onClose={handleClose}
            maxWidth={"sm"}
            fullWidth={true}
          >
            <DialogTitle>Přidat nový komentář</DialogTitle>
            <DialogContent>
              {submitted && (
                <DialogContentText>Díky za komentář!</DialogContentText>
              )}
              {!submitted && (
                <>
                  <Box mb={2}>
                    <TextField
                      size={"small"}
                      variant={"outlined"}
                      label={"Jméno"}
                      fullWidth
                      name={"email"}
                      value={form.email}
                      onChange={handleChange}
                      error={formError.hasOwnProperty("email")}
                      helperText={
                        formError.hasOwnProperty("email") ? formError.email : ""
                      }
                    />
                  </Box>
                  <Box mb={2}>
                    <TextField
                      size={"small"}
                      variant={"outlined"}
                      multiline
                      rows={4}
                      label={"Text komentáře"}
                      fullWidth
                      name={"text"}
                      value={form.text}
                      onChange={handleChange}
                      error={formError.hasOwnProperty("text")}
                      helperText={
                        formError.hasOwnProperty("text") ? formError.text : ""
                      }
                    />
                  </Box>
                </>
              )}
            </DialogContent>
            <DialogActions>
              {submitted && (
                <Button
                  variant={"text"}
                  color={"secondary"}
                  size={"small"}
                  onClick={handleClose}
                >
                  Zavřít
                </Button>
              )}
              {!submitted && (
                <>
                  <Button
                    variant={"text"}
                    color={"secondary"}
                    size={"small"}
                    onClick={handleClose}
                  >
                    Zrušit
                  </Button>
                  <Button
                    variant={"contained"}
                    color={"primary"}
                    onClick={handleSubmit}
                  >
                    Přidat komentář
                  </Button>
                </>
              )}
            </DialogActions>
          </Dialog>
        </Box>
      </Box>
    </>
  );
};

export default Comment;
