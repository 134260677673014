import React from "react";
import Video from "./Video";
import Test from "./Test";
import { baseUrl } from "../Api/Api";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import red from "@material-ui/core/colors/red";
import Loading from "../Loading/Loading";
import { useCookies } from "react-cookie";
import { changeTheme } from "../../utils/utils";
import { useDispatch } from "react-redux";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { logEvent } from "../../utils/logEvent/logEvent";

const useStyle = makeStyles((theme) => ({
  vimeo: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(-2),
      marginRight: theme.spacing(-2),
      marginLeft: theme.spacing(-2),
    },
  },
  buttons: {
    margin: theme.spacing(0, 0, 2, 0),
  },
  paper: {
    margin: theme.spacing(0, 0, 2, 0),
  },
  downloads: {
    display: "flex",
    justifyContent: "left",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(1, 1, 1, 0),
    },
  },
  paperBody: {
    padding: theme.spacing(2),
    "& > hr": {
      height: 2,
      backgroundColor: "#e7e9ee",
      margin: theme.spacing(1, 0),
    },
  },
  buyInfo: {
    padding: theme.spacing(5, 0),
    textAlign: "center",
  },
  buyInfoText: {
    marginBottom: theme.spacing(5),
  },
  description: {
    fontWeight: "bold",
    color: theme.palette.text.secondary,
  },
  lessons: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
  listItem: {
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.secondary.contrastText,
    },
  },
  selected: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.contrastText,
  },
  answerCorrect: {
    color: green[800],
  },
  answerBad: {
    color: red[800],
  },
  questionCorrect: {
    backgroundColor: green[100],
  },
  questionBad: {
    backgroundColor: red[100],
  },
  questionInfoCorrect: {
    position: "absolute",
    zIndex: 1,
    top: theme.spacing(-2),
    right: theme.spacing(2),
    backgroundColor: green[200],
    color: green[900],
    "& > *": {
      color: green[900],
    },
  },
  questionInfoBad: {
    position: "absolute",
    zIndex: 1,
    top: theme.spacing(-2),
    right: theme.spacing(2),
    backgroundColor: red[200],
    color: red[900],
    "& > *": {
      color: red[900],
    },
  },
}));

const Course = (props) => {
  const [cookies, setCookie, removeCookie] = useCookies();

  const { slug } = props;

  const classes = useStyle();

  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);

  const [course, setCourse] = React.useState([]);

  const sendDataToGTM = useGTMDispatch();

  const fetch = (slug, withLoading, token = "") => {
    if (withLoading) {
      setLoading(true);
    }
    const courseUrl = baseUrl + "/courses/" + slug;
    const config = {
      headers: {
        Authorization: token,
      },
    };
    axios
      .get(courseUrl, config)
      .then((response) => {
        setCourse(response.data);
        setLoading(false);
        changeTheme(dispatch, response.data.category.schoolType.slug);
      })
      .catch((errors) => {
        setError(errors);
        setLoading(false);
      });
  };

  React.useEffect(() => {
    const token = "token" in cookies ? cookies["token"] : "";
    fetch(slug, true, token);
  }, [slug]);

  React.useEffect(() => {
    if (course.title) {
      document.title = course.title + " | Videonalejvarny";

      const data = {
        event: "view_course",
        courseType: course.courseType.code,
        id: course.id,
        title: course.title,
        price: course.price,
      };
      sendDataToGTM(data);
    } else {
      document.title = "Videonalejvarny";
    }
  }, [course]);

  const unlockCourse = (token) => {
    setCookie("token", token, { path: "/" });
    fetch(slug, false, token);

    if (!!course) {
      // logEvent({ type: "login", email: token });
      const data = {
        event: "purchase_course",
        courseType: course.courseType.code,
        id: course.id,
        title: course.title,
        price: course.price,
      };
      sendDataToGTM(data);
    }
  };

  if (loading) {
    return <Loading />;
  }

  if (course.courseType.code === "video") {
    return (
      <Video course={course} classes={classes} unlockCourse={unlockCourse} />
    );
  }

  if (course.courseType.code === "test") {
    return <Test course={course} classes={classes} />;
  }

  return "";
};

export default Course;
