import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import { useCookies } from "react-cookie";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import axios from "axios";
import { baseUrl } from "../Api/Api";
import Alert from "@material-ui/lab/Alert";
import LoyaltyIcon from "@material-ui/icons/Loyalty";
import { Box } from "@material-ui/core";
import { writeLogin } from "../../utils/firebase/firebase";
import { logEvent } from "../../utils/logEvent/logEvent";
//import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => {
  return {
    dialogBody: {
      padding: theme.spacing(2),
    },
    marginBottom: {
      marginBottom: theme.spacing(2),
    },
    credits: {
      color: theme.palette.primary.main,
    },
  };
});

const LoggedUserMenu = (props) => {
  const { email, logout, showCredits, showAccount } = props;

  const classes = useStyles();

  const [cookies, setCookie, removeCookie] = useCookies();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [creditsAmount, setCreditsAmount] = React.useState(0);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    handleClose();
  };

  React.useEffect(() => {
    axios({
      method: "GET",
      url: baseUrl + "/credit/me",
      headers: {
        Authorization: cookies.tokenComposite || "",
        "Content-Type": "multipart/form-data",
      },
    })
      .then(function (response) {
        if (response.status === 200 && response.data) {
          setCreditsAmount(response.data.creditsAmount);
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  }, [cookies]);

  return (
    <>
      {showCredits && (
        <Box
          className={classes.credits}
          style={{
            marginTop: 8,
            marginBottom: 8,
            display: "flex",
            alignItems: "center",
            fontWeight: "bold",
          }}
        >
          <LoyaltyIcon
            style={{ marginLeft: 16, marginRight: 4 }}
            fontSize={"small"}
          />{" "}
          {creditsAmount} kreditů
        </Box>
      )}
      {showAccount && (
        <>
          <Button
            style={{ marginLeft: 8, marginRight: 16 }}
            onClick={handleClick}
            variant={"text"}
            color={"primary"}
            size={"small"}
            endIcon={<ArrowDropDownIcon />}
          >
            {email}
          </Button>
          <Menu
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleLogout}>Odhlásit se</MenuItem>
          </Menu>
        </>
      )}
    </>
  );
};

const FormDialogLogIn = (props) => {
  const {
    showCredits = true,
    showAccount = true,
    showLoginButton = true,
  } = props;

  const classes = useStyles();

  //const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);

  const [cookies, setCookie, removeCookie] = useCookies();

  const [email, setEmail] = React.useState("");

  const [formEmail, setFormEmail] = React.useState("");
  const [formPassword, setFormPassword] = React.useState("");
  const [message, setMessage] = React.useState("");

  React.useEffect(() => {
    if ("token" in cookies) {
      setEmail(cookies["token"]);
    }
  }, [cookies]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const submitForm = () => {
    setMessage("");

    const bodyFormData = new FormData();
    bodyFormData.append("email", formEmail);
    bodyFormData.append("password", formPassword);
    bodyFormData.append("api", "true");

    axios({
      method: "post",
      url: baseUrl + "/login",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        if (
          response.data &&
          response.data.message &&
          response.data.message === "Auth successfully"
        ) {
          logEvent({ type: "login", email: formEmail });

          const tokenSecret = Math.round(Math.random() * 1000000);
          setCookie("token", response.data.token, { path: "/" });
          setCookie("tokenComposite", response.data.tokenComposite, {
            path: "/",
          });
          setCookie("tokenSecret", tokenSecret, { path: "/" });
          writeLogin(
            "loggedUser/" + response.data.tokenComposite.split("__")[0],
            {
              tokenSecret: tokenSecret,
            },
            () => {
              window.location.reload();
            },
            (error) => {
              console.log(error);
            }
          );

          //handleClose();
          //window.location.reload();
        } else {
          setMessage("Neplatné přihlašovací údaje.");
        }
      })
      .catch(function (response) {
        setMessage("Neplatné přihlašovací údaje.");
        //handle error
        //console.log(response);
      });
  };

  const logout = () => {
    logEvent({ type: "logout", email: email });
    removeCookie("token", { path: "/" });
    removeCookie("tokenComposite", { path: "/" });
    removeCookie("tokenSecret", { path: "/" });
    //setEmail("");
    setTimeout(() => {
      window.location.reload();
    }, 1250);
  };

  if (email !== "") {
    return (
      <LoggedUserMenu
        email={email}
        logout={logout}
        showCredits={showCredits}
        showAccount={showAccount}
      />
    );
  }

  return (
    <React.Fragment>
      {showLoginButton && (
        <Button variant="contained" color="primary" onClick={handleClickOpen}>
          Přihlásit se
        </Button>
      )}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle className={classes.dialogBody}>Přihlásit se</DialogTitle>
        <DialogContent className={classes.dialogBody}>
          <DialogContentText>
            Přihlaste se svým e-mailem a heslem, které jste obdrželi e-mailem
            při registraci.
          </DialogContentText>
          {message && (
            <Alert severity="error" style={{ marginBottom: "16px" }}>
              {message}
            </Alert>
          )}
          <TextField
            className={classes.marginBottom}
            autoFocus
            label="E-mail"
            type="email"
            variant={"outlined"}
            fullWidth
            value={formEmail}
            onChange={(event) => {
              setFormEmail(event.target.value);
            }}
          />
          <TextField
            label="Heslo"
            type="password"
            variant={"outlined"}
            fullWidth
            value={formPassword}
            onChange={(event) => {
              setFormPassword(event.target.value);
            }}
          />
        </DialogContent>
        <Divider />
        <DialogActions className={classes.dialogBody}>
          <Button onClick={handleClose} color="primary">
            Zrušit
          </Button>
          <Button onClick={submitForm} color="primary" variant={"contained"}>
            Přihlásit se
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default FormDialogLogIn;
