import Layout from "../components/Layout/Layout";
import React from "react";
import Container from "@material-ui/core/Container";
import Course from "../components/Course/Course";

const Course_Page = (props) => {
  const { slug } = props.match.params;

  return (
    <Layout title={"Kurz"}>
      <Container>
        <Course slug={slug} />
      </Container>
    </Layout>
  );
};

export default Course_Page;
