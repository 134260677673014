import React from "react";
import Paper from "@material-ui/core/Paper";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Grid from "@material-ui/core/Grid";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import AdjustIcon from "@material-ui/icons/Adjust";
import ListSubheader from "@material-ui/core/ListSubheader";
import Question from "./Question";
import { Box } from "@material-ui/core";
import { truncateText } from "../Utils/Utils";
import { useSelector } from "react-redux";

const getIconForQuestion = (active, answered, answeredCorrectly) => {
  if (active) {
    if (answered) {
      if (answeredCorrectly) {
        return <CheckCircleOutlineIcon />;
      }
      return <CancelOutlinedIcon />;
    }
    return <AdjustIcon />;
  }
  return <RadioButtonUncheckedIcon />;
};

const QuestionItem = (props) => {
  const {
    classes,
    question,
    active,
    changeQuestion,
    answered,
    answeredCorrectly,
  } = props;

  const theme = useSelector((state) => state.app.theme);

  return (
    <React.Fragment>
      <ListItem
        button
        className={classes.listItem + " " + (active ? classes.selected : null)}
        onClick={() => {
          changeQuestion(question);
        }}
      >
        <ListItemIcon style={{ color: theme.palette.primary.main }}>
          {getIconForQuestion(active, answered, answeredCorrectly)}
        </ListItemIcon>
        <ListItemText primary={truncateText(question.text)} />
      </ListItem>
      <Box
        style={{
          height: 2,
          width: "100%",
          background: theme.palette.secondary.light,
        }}
      />
    </React.Fragment>
  );
};

const Test = (props) => {
  const { classes, course } = props;

  const [question, setQuestion] = React.useState(false);
  const [answered, setAnswered] = React.useState(false);
  const [answeredCorrectly, setAnsweredCorrectly] = React.useState(false);

  const theme = useSelector((state) => state.app.theme);

  const changeQuestion = (q) => {
    if (q !== question) {
      setQuestion(q);
      setAnswered(false);
      setAnsweredCorrectly(false);
    }
  };

  const makeAnswer = (answeredCorrectly) => {
    setAnswered(true);
    setAnsweredCorrectly(answeredCorrectly);
  };

  React.useEffect(() => {
    if (course.questions && course.questions.length > 0) {
      setQuestion(course.questions[0]);
    }
  }, [course]);

  return (
    <Grid container direction={"row"} spacing={2}>
      <Grid item xs={12} sm={12} md={8}>
        <Question
          question={question}
          course={course}
          classes={classes}
          changeQuestion={changeQuestion}
          makeAnswer={makeAnswer}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        {course.questions && (
          <React.Fragment>
            <Paper className={classes.lessons}>
              <List
                component="nav"
                subheader={
                  <ListSubheader
                    component="div"
                    style={{ color: theme.palette.primary.main }}
                  >
                    Otázky
                  </ListSubheader>
                }
              >
                {course.questions.map((q) => {
                  return (
                    <QuestionItem
                      key={course.questions.indexOf(q)}
                      question={q}
                      classes={classes}
                      active={q === question} //TODO - toto přepsat na jednoznačný identifikátor a ne code
                      changeQuestion={changeQuestion}
                      answered={answered}
                      answeredCorrectly={answeredCorrectly}
                    />
                  );
                })}
              </List>
            </Paper>
          </React.Fragment>
        )}
      </Grid>
    </Grid>
  );
};

export default Test;
