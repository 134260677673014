import axios from "axios";
import { baseUrl } from "../../components/Api/Api";

export const logEvent = (data) => {
  axios({
    method: "POST",
    url: baseUrl + "/log-event",
    data: data,
  });
};
