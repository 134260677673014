import { createMuiTheme } from "@material-ui/core/styles";
import {
  schoolTypeHighSchool,
  schoolTypeUniversity,
  schoolTypeXNA,
} from "../../constants/constants";

export const colorUniversity = "#ec0c66";
export const colorHighSchool = "#0068be";
export const colorXNA = "#656c7a";

export const colors = {
  color1: colorUniversity,
  color2: "#d3d4d4",
  color3: "#231f20",
  color4: "#ffffff",
  color5: "#656c7a",
};

export const colors2 = {
  color1: colorHighSchool,
  color2: "#d3d4d4",
  color3: "#231f20",
  color4: "#ffffff",
  color5: "#656c7a",
};

export const colors3 = {
  color1: colorXNA,
  color2: "#d3d4d4",
  color3: "#231f20",
  color4: "#ffffff",
  color5: "#656c7a",
};

const themes = {
  [schoolTypeUniversity]: createMuiTheme({
    palette: {
      primary: { main: colors.color1, contrastText: colors.color4 },
      secondary: { main: colors.color3, contrastText: colors.color4 },
      text: { secondary: colors.color5 },
      background: {
        default: colors.color2,
        white: colors.color4,
        black: colors.color3,
      },
    },
    shape: {
      borderRadius: "10px",
    },
    shadows: ["none"],
  }),
  [schoolTypeHighSchool]: createMuiTheme({
    palette: {
      primary: { main: colors2.color1, contrastText: colors2.color4 },
      secondary: { main: colors2.color3, contrastText: colors2.color4 },
      text: { secondary: colors2.color5 },
      background: {
        default: colors2.color2,
        white: colors2.color4,
        black: colors2.color3,
      },
    },
    shape: {
      borderRadius: "10px",
    },
    shadows: ["none"],
  }),
  [schoolTypeXNA]: createMuiTheme({
    palette: {
      primary: { main: colors3.color1, contrastText: colors3.color4 },
      secondary: { main: colors3.color3, contrastText: colors3.color4 },
      text: { secondary: colors3.color5 },
      background: {
        default: colors3.color2,
        white: colors3.color4,
        black: colors3.color3,
      },
    },
    shape: {
      borderRadius: "10px",
    },
    shadows: ["none"],
  }),
};

export const getTheme = (schoolType) => {
  return themes[schoolType];
};
