import { getTheme } from "../components/Theme/Theme";
import { schoolTypeXNA } from "../constants/constants";

const initialState = {
  theme: getTheme(schoolTypeXNA),
  token: null,
  user: {
    id: null,
    email: null,
    shareLink: null,
    creditsAmount: null,
  },
};

export const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_TOKEN": {
      return { ...state, token: action.payload.token };
    }
    case "SET_USER": {
      return { ...state, user: action.payload.user };
    }
    case "SET_THEME": {
      return { ...state, theme: action.payload };
      // return Object.assign({}, {theme: action.payload});
      // // const copy = {...state};
      // // copy.theme = action.payload;
      // // return copy;
      // return produce(state, (draft) => {
      //   draft.theme = action.payload;
      // })
    }
    default: {
      return state;
    }
  }
};
