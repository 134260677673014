import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Divider from "@material-ui/core/Divider";
import FormDialogLogIn from "../FormDialogLogIn/FormDialogLogIn";
import { Box, Button, Drawer, IconButton, MenuItem } from "@material-ui/core";
import { Link } from "react-router-dom";
import { baseUrl } from "../Api/Api";
import MenuIcon from "@material-ui/icons/Menu";
import ForceLogout from "../ForceLogout/ForceLogout";
import FormDialogRegister from "../FormDialogRegister/FormDialogRegister";

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: theme.palette.background.white,
  },
  appBarToolBar: {
    display: "flex",
  },
  title: {
    flexGrow: 1,
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    //backgroundColor: theme.palette.background.default,
    paddingTop: theme.spacing(2),
  },
  logo: {
    maxHeight: 36,
  },
  footer: {
    marginTop: theme.spacing(2),
    borderTop: "solid 1px rgba(0, 0, 0, 0.12)",
    padding: theme.spacing(2),
  },
}));

const Layout = ({ children, title }) => {
  const classes = useStyles();

  const [state, setState] = React.useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;

  React.useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  const displayDesktop = () => {
    return (
      <Box style={{ display: "flex", alignItems: "center" }}>
        <Link to={"/o-nas"}>
          <Button style={{ marginRight: 8 }}>O nás</Button>
        </Link>
        <Link to={"/recenze"}>
          <Button style={{ marginRight: 8 }}>Recenze</Button>
        </Link>
        <Link to={"/faq"}>
          <Button style={{ marginRight: 8 }}>FAQ</Button>
        </Link>
        <Link to={"/kreditovy-system"}>
          <Button style={{ marginRight: 8, fontWeight: 900 }}>
            Kreditový systém
          </Button>
        </Link>
        <Box
          style={{
            marginLeft: 8,
            borderLeft: "solid 1px rgba(0, 0, 0, 0.12)",
            height: 32,
          }}
        />
        <FormDialogLogIn />
        <FormDialogRegister />
        <ForceLogout />
      </Box>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <>
        <IconButton
          {...{
            edge: "start",
            color: "primary",
            "aria-label": "menu",
            "aria-haspopup": "true",
            onClick: handleDrawerOpen,
          }}
        >
          <MenuIcon />
        </IconButton>

        <Drawer
          {...{
            anchor: "right",
            open: drawerOpen,
            onClose: handleDrawerClose,
            className: classes.drawer,
          }}
        >
          <Box style={{ display: "flex", flexDirection: "column" }}>
            <Link
              {...{
                // component: Link,
                to: "/o-nas",
                style: { textDecoration: "none" },
                onClick: () => {
                  setState({ ...state, drawerOpen: false });
                },
              }}
            >
              <MenuItem>O nás</MenuItem>
            </Link>
            <Link
              {...{
                to: "/recenze",
                style: { textDecoration: "none" },
                onClick: () => {
                  setState({ ...state, drawerOpen: false });
                },
              }}
            >
              <MenuItem>Recenze</MenuItem>
            </Link>
            <Link
              {...{
                to: "/faq",
                style: { textDecoration: "none" },
                onClick: () => {
                  setState({ ...state, drawerOpen: false });
                },
              }}
            >
              <MenuItem>FAQ</MenuItem>
            </Link>
            <Link
              {...{
                to: "/kreditovy-system",
                style: { textDecoration: "none" },
                onClick: () => {
                  setState({ ...state, drawerOpen: false });
                },
              }}
            >
              <MenuItem style={{ fontWeight: 900 }}>Kreditový systém</MenuItem>
            </Link>
            <Box
              style={{
                marginTop: 8,
                marginBottom: 8,
                borderTop: "solid 1px rgba(0, 0, 0, 0.12)",
                width: "100%",
                height: "1px",
              }}
            />
            <FormDialogLogIn />
            <FormDialogRegister />
            <ForceLogout />
          </Box>
        </Drawer>
      </>
    );
  };

  return (
    <>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar} color={"default"}>
        <Toolbar className={classes.appBarToolBar}>
          <Box className={classes.title}>
            <a href={"/"}>
              <img
                className={classes.logo}
                src="/logo@2x.png"
                alt={"Video Nalejvárny"}
                title={"Video Nalejvárny"}
              />
            </a>
          </Box>
          <Box>{mobileView ? displayMobile() : displayDesktop()}</Box>
        </Toolbar>
        <Divider />
      </AppBar>
      <Box
        display={"flex"}
        flexDirection={"column"}
        style={{ minHeight: "100%" }}
      >
        <Box className={classes.content} flexGrow={1}>
          <div className={classes.toolbar} />
          {children}
        </Box>
        <Box className={classes.footer}>
          <a href={baseUrl + "/files/obchodni-podminky"}>Obchodní podmínky</a>
          <a
            style={{ marginLeft: 16 }}
            href={baseUrl + "/files/zasady-ochrany-osobnich-udaju_VN.pdf"}
          >
            Zásady ochrany osobních údajů
          </a>
        </Box>
      </Box>
    </>
  );
};

export default Layout;
