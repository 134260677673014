import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  ElementsConsumer,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Grid from "@material-ui/core/Grid";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { formatPrice } from "../Utils/Utils";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Axios from "axios";
import { baseUrl } from "../Api/Api";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { useCookies } from "react-cookie";
import FormDialogLogIn from "../FormDialogLogIn/FormDialogLogIn";
import axios from "axios";
import { Slider } from "@material-ui/core";
import FormDialogRegister from "../FormDialogRegister/FormDialogRegister";

// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const useStyles = makeStyles((theme) => ({
  dialogBody: {
    padding: theme.spacing(2),
  },
  marginBottom: {
    marginBottom: theme.spacing(4),
  },
}));

const StripeInput = ({ component: Component, inputRef, ...props }) => {
  const elementRef = React.useRef();
  React.useImperativeHandle(inputRef, () => ({
    focus: () => elementRef.current.focus,
  }));
  return (
    <Component
      onReady={(element) => (elementRef.current = element)}
      {...props}
    />
  );
};

const FormCheckout = (props) => {
  const initialMessage = {
    status: "hidden",
    title: "",
    message: "",
  };

  const { course, stripe, elements, unlockCourse } = props;

  const [cookies, setCookies, removeCookies] = useCookies();

  const [message, setMessage] = React.useState(initialMessage);
  const [paymentLoading, setPaymentLoading] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [firstname, setFirstname] = React.useState("");
  const [lastname, setLastname] = React.useState("");
  const [addressStreet, setAddressStreet] = React.useState("");
  const [addressStreetNumber, setAddressStreetNumber] = React.useState("");
  const [addressCity, setAddressCity] = React.useState("");
  const [addressPostCode, setAddressPostCode] = React.useState("");
  const [banned, setBanned] = React.useState(false);
  const [vopCheck, setVopCheck] = React.useState(false);

  const [creditsAmount, setCreditsAmount] = React.useState(0);
  const [priceInCredits, setPriceInCredits] = React.useState(0);
  const [loggedIn, setLoggedIn] = React.useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const card = elements.getElement(CardNumberElement); //získání cardElementu musí být dříve před setMessage()

    const shareLink = cookies.shareLink || "";

    const data = {
      email: email,
      courseId: course.id,
      price: course.price * 1 - priceInCredits * 1,
      priceInCredits: priceInCredits * 1,
      shareLink: shareLink,
    };

    if (data.price === 0) {
      setMessage({
        status: "info",
        title: "Zpracovávám",
        message:
          "Počkejte prosím na dokončení zpracování. Nezavírejte toto okno.",
      });
      setPaymentLoading(true);

      Axios.post(
        baseUrl + "/payment-intent/create-without-price", //1 - call for a new payment intent
        data
      ).then((response) => {
        if (response.status === 200) {
          unlockCourse(email); //TODO - jiný token
          setMessage({
            status: "success",
            title: "Získání kurzu proběhlo",
            message: "Kurz byl odemčen",
          });
        } else {
          setMessage({
            status: "error",
            title: "Získání kurzu neproběhlo",
            message: "Opakujte prosím nákup",
          });
        }
      });
    } else {
      setMessage({
        status: "info",
        title: "Platba probíhá",
        message: "Počkejte prosím na potvrzení platby. Nezavírejte toto okno.",
      });
      setPaymentLoading(true);

      Axios.post(
        baseUrl + "/payment-intent/create", //1 - call for a new payment intent
        data
      ).then((response) => {
        //2 - receive payment intent
        let paymentIntent = response.data; //3 - save payment intent secret
        stripe
          .confirmCardPayment(
            paymentIntent.client_secret, //4 - confirm cardPayment and use payment intent secret
            {
              payment_method: {
                card: card,
                billing_details: {
                  email: email,
                },
              }, //5 - use payment method element
            }
          )
          .then((result) => {
            if (result.error || result.paymentIntent.status !== "succeeded") {
              setMessage({
                status: "error",
                title: "Platba nebyla úspěšná",
                message: "Opakujte ji prosím",
              });
            } else {
              Axios.post(baseUrl + "/payment-intent/confirm", {
                paymentIntentId: paymentIntent.id,
              }).then((result2) => {
                if (result2.status === 200) {
                  unlockCourse(email); //TODO - jiný token
                  setMessage({
                    status: "success",
                    title: "Platba byla úspěšná",
                    message: "Kurz byl odemčen",
                  });
                } else {
                  setMessage({
                    status: "error",
                    title: "Platba nebyla úspěšná",
                    message: "Opakujte ji prosím",
                  });
                }
              });
            }
          });
      });
    }
  };

  const getCreditSpendings = () => {
    let min = 0;
    let max = 0;
    let steps = [];

    if (creditsAmount) {
      max = Math.min(course?.maxPriceInCredits || 0, creditsAmount);
      for (let i = min; i < max; i = i + Math.round(max / 3)) {
        let sub = i;
        let newPrice = course.price * 1 - sub;
        if (newPrice >= 30 || newPrice === 0) {
          steps.push(sub);
        }
      }
      steps.push(max);
    }

    return {
      min,
      max,
      steps,
    };
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  React.useEffect(() => {
    axios({
      method: "GET",
      url: baseUrl + "/credit/me",
      headers: {
        Authorization: cookies.tokenComposite || "",
        "Content-Type": "multipart/form-data",
      },
    })
      .then(function (response) {
        if (response.status === 200 && response.data) {
          setCreditsAmount(response.data.creditsAmount);
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  }, [cookies]);

  React.useEffect(() => {
    if (cookies?.token && cookies?.tokenComposite) {
      setEmail(cookies.tokenComposite.split("__")[1]);
      setLoggedIn(true);
    }
  }, [cookies]);

  React.useEffect(() => {
    const v = validateEmail(email);
    if (v) {
      axios({
        method: "post",
        url: baseUrl + "/check-email",
        data: { email },
      }).then(function (response) {
        if (response.status === 200 && response.data) {
          if (!!response.data.banned) {
            setBanned(!!response.data.banned);
          }
        }
      });
    }
  }, [email]);

  return (
    <React.Fragment>
      <Alert
        severity={message.status}
        style={{ display: message.status === "hidden" ? "none" : "flex" }}
      >
        <AlertTitle>{message.title}</AlertTitle>
        {message.message}
      </Alert>
      <form
        onSubmit={handleSubmit}
        style={{ display: message.status === "hidden" ? "block" : "none" }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              disabled={loggedIn || paymentLoading}
              label="E-mail"
              name="email"
              placeholder={"E-mail"}
              variant="outlined"
              required
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              error={banned}
              helperText={
                banned
                  ? "Tento e-mail byl zabanovaný. Nelze jej použít pro nákup."
                  : ""
              }
            />
          </Grid>
          {getCreditSpendings().max > 0 && (
            <>
              <Grid item xs={12}>
                <Typography color={"textSecondary"}>
                  Pro úhradu tohoto kurzu <strong>{course.title}</strong> lze
                  využít až <strong>{getCreditSpendings().max}</strong> vašich
                  kreditů. Zvolte si kolik z nich použijete.
                </Typography>
              </Grid>
              {/*<Grid item xs={12}>*/}
              {/*  <Box*/}
              {/*    style={{*/}
              {/*      display: "flex",*/}
              {/*      alignItems: "center",*/}
              {/*    }}*/}
              {/*  >*/}
              {/*    <Box sx={{ flexGrow: 1, marginRight: 16 }}>*/}
              {/*      <Slider*/}
              {/*        defaultValue={getCreditSpendings().max / 2}*/}
              {/*        min={getCreditSpendings().min}*/}
              {/*        max={getCreditSpendings().max}*/}
              {/*        valueLabelDisplay="auto"*/}
              {/*        size={"large"}*/}
              {/*      />*/}
              {/*    </Box>*/}
              {/*    <Box>*/}
              {/*      <strong>{priceInCredits} kreditů</strong>*/}
              {/*    </Box>*/}
              {/*  </Box>*/}
              {/*</Grid>*/}
              <Box
                style={{
                  marginTop: 8,
                  marginBottom: 16,
                  display: "flex",
                  justifyContent: "space-evenly",
                  width: "100%",
                }}
              >
                {getCreditSpendings().steps.map((step) => {
                  return (
                    <Box key={step}>
                      <Button
                        color={"primary"}
                        variant={
                          priceInCredits === step ? "contained" : "outlined"
                        }
                        onClick={() => {
                          setPriceInCredits(step * 1);
                        }}
                      >
                        {step} kreditů
                      </Button>
                    </Box>
                  );
                })}
              </Box>
            </>
          )}
          <Grid
            item
            xs={12}
            style={{
              display:
                course.price * 1 === priceInCredits * 1 ? "none" : "flex",
            }}
          >
            <TextField
              disabled={paymentLoading}
              label="Číslo karty"
              name="ccnumber"
              variant="outlined"
              required
              fullWidth
              InputLabelProps={{ shrink: true }}
              InputProps={{
                inputComponent: StripeInput,
                inputProps: {
                  component: CardNumberElement,
                },
              }}
            />
          </Grid>
          <Grid
            item
            container
            spacing={2}
            style={{
              display:
                course.price * 1 === priceInCredits * 1 ? "none" : "flex",
            }}
          >
            <Grid item xs={8}>
              <TextField
                disabled={paymentLoading}
                label="Datum expirace"
                name="ccexp"
                variant="outlined"
                required
                fullWidth
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: StripeInput,
                  inputProps: {
                    component: CardExpiryElement,
                  },
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                disabled={paymentLoading}
                label="CVC"
                name="cvc"
                variant="outlined"
                required
                fullWidth
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: StripeInput,
                  inputProps: {
                    component: CardCvcElement,
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={vopCheck}
                  onChange={() => {
                    setVopCheck(!vopCheck);
                  }}
                />
              }
              label={
                <label>
                  Souhlasím s{" "}
                  <a
                    href={baseUrl + "/files/obchodni-podminky"}
                    target={"_blank"}
                    style={{ textDecoration: "underline" }}
                    rel="noreferrer"
                  >
                    Obchodními podmínkami
                  </a>{" "}
                  a se{" "}
                  <a
                    href={
                      baseUrl + "/files/zasady-ochrany-osobnich-udaju_VN.pdf"
                    }
                    target={"_blank"}
                    style={{ textDecoration: "underline" }}
                    rel="noreferrer"
                  >
                    Zásadami ochrany osobních údajů
                  </a>
                </label>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth={true}
              type={"submit"}
              variant={"contained"}
              color={"primary"}
              size={"large"}
              startIcon={<ShoppingCartIcon />}
              disabled={paymentLoading === true || vopCheck === false || banned}
            >
              {course.price * 1 === priceInCredits * 1
                ? "Získat kurz zdarma"
                : "Zaplatit " + formatPrice(course.price - priceInCredits)}
            </Button>
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
};

const FormDialogPayment = (props) => {
  const classes = useStyles();

  const { course, unlockCourse } = props;

  const stripePublicKey =
    course.stripe?.publicKey || process.env.REACT_APP_STRIPE_PUBLIC_KEY;
  const stripePromise = loadStripe(stripePublicKey);
  // console.log(course.stripe?.publicKey);

  const [cookies, setCookies, removeCookies] = useCookies();

  const [open, setOpen] = React.useState(false);

  const [loggedIn, setLoggedIn] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUnlockCourse = (token) => {
    setTimeout(() => {
      unlockCourse(token);
      handleClose();
    }, 1250);
  };

  React.useEffect(() => {
    if (cookies.token && cookies.tokenComposite) {
      setLoggedIn(true);
    }
  }, [cookies]);

  if (!loggedIn) {
    return (
      <React.Fragment>
        <Button
          variant={"contained"}
          color={"primary"}
          size={"large"}
          onClick={handleClickOpen}
          startIcon={<ShoppingCartIcon />}
        >
          Koupit celý kurz za {formatPrice(course.price)}
        </Button>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle className={classes.dialogBody}>
            Koupit kurz <strong>{course.title}</strong>
          </DialogTitle>
          <DialogContent color={"textSecondary"} className={classes.dialogBody}>
            {!loggedIn && (
              <>
                <Typography
                  className={classes.marginBottom}
                  color={"textSecondary"}
                >
                  Pro nákup kurzu je potřeba být přihlášen. Pokud ještě nemáte
                  účet, nejdříve se zaregistrujte.
                </Typography>
                <Box style={{ textAlign: "center", marginBottom: 32 }}>
                  <FormDialogLogIn
                    showCredits={false}
                    showLoginButton={true}
                    showAccount={false}
                  />
                  <FormDialogRegister />
                </Box>
              </>
            )}
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Button
        variant={"contained"}
        color={"primary"}
        size={"large"}
        onClick={handleClickOpen}
        startIcon={<ShoppingCartIcon />}
      >
        Koupit celý kurz za {formatPrice(course.price)}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle className={classes.dialogBody}>
          Koupit kurz <strong>{course.title}</strong>
        </DialogTitle>
        <DialogContent color={"textSecondary"} className={classes.dialogBody}>
          <Typography className={classes.marginBottom} color={"textSecondary"}>
            Zadejte prosím svůj e-mail a platební údaje. Jakmile obdržíme
            platbu, získáte přístup k&nbsp;celému kurzu. Na zadaný e-mail vám
            pošleme heslo, pomocí kterého si budete moci kurz kdykoliv znovu
            zobrazit.
          </Typography>

          {!loggedIn && (
            <>
              <Typography
                className={classes.marginBottom}
                color={"textSecondary"}
              >
                Pokud už u nás máte účet, přihlaste se! Budete moci pro úhradu
                kurzu použít své kredity.
              </Typography>
              <Box style={{ textAlign: "center", marginBottom: 32 }}>
                <FormDialogLogIn
                  showCredits={false}
                  showLoginButton={true}
                  showAccount={false}
                />
              </Box>
            </>
          )}

          <Box className={classes.marginBottom}>
            <Elements stripe={stripePromise}>
              <ElementsConsumer>
                {({ stripe, elements }) => (
                  <FormCheckout
                    stripe={stripe}
                    elements={elements}
                    course={course}
                    unlockCourse={handleUnlockCourse}
                  />
                )}
              </ElementsConsumer>
            </Elements>
          </Box>
          <Typography color={"textSecondary"}>
            <small>
              Platbu a zabezpečení platebních údajů zajišťuje{" "}
              <a
                href="https://stripe.com/en-cz"
                target={"_blank"}
                rel="noreferrer"
              >
                Stripe
              </a>
              . Platební údaje se neukládají.
            </small>
          </Typography>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default FormDialogPayment;
