import { Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
}));

const PaperTitle = (props) => {
  const classes = useStyle();

  const { lessonTitle, categoryTitle, courseTitle } = props;

  return (
    <Box display={"flex"} className={classes.marginBottom}>
      <Box flexGrow={1}>
        <Typography variant={"caption"} gutterBottom>
          {lessonTitle}
        </Typography>
      </Box>
      <Box style={{ textAlign: "right" }}>
        <Typography variant={"caption"} gutterBottom>
          {categoryTitle} &bull; {courseTitle}
        </Typography>
      </Box>
    </Box>
  );
};

export default PaperTitle;
