import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Collapse,
  Container,
  makeStyles,
} from "@material-ui/core";
import Layout from "../Layout/Layout";
import axios from "axios";
import { baseUrl } from "../Api/Api";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: "center",
    fontSize: 36,
    fontWeight: 800,
    color: "#000",
    lineHeight: 1.15,
    [theme.breakpoints.up("md")]: {
      fontSize: 58,
    },
  },
  subtitle: {
    color: theme.palette.primary.main,
    fontWeight: 800,
  },
}));

const CollapseCard = (props) => {
  const { title, children } = props;

  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen((prev) => !prev);
  };

  return (
    <Card style={{ marginTop: 24, marginBottom: 24 }}>
      <Box
        style={{
          paddingLeft: 24,
          paddingRight: 24,
          paddingTop: 24,
          paddingBottom: 24,
          fontSize: 18,
          fontWeight: 600,
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
        }}
        onClick={() => {
          toggleOpen();
        }}
      >
        <Box sx={{ flexGrow: 1 }}>{title}</Box>
        <DoubleArrowIcon
          color={"primary"}
          style={{
            transform: open ? "rotate(-90deg)" : "rotate(90deg)",
            transition: "all 500ms",
          }}
        />
      </Box>
      <Collapse in={open}>
        <CardContent
          style={{
            paddingLeft: 24,
            paddingRight: 24,
            paddingBottom: 24,
            paddingTop: 0,
          }}
        >
          {children}
        </CardContent>
      </Collapse>
    </Card>
  );
};

const FaqPage = () => {
  const classes = useStyles();
  const [faqs, setFaqs] = React.useState([]);

  React.useEffect(() => {
    axios({
      method: "GET",
      url: baseUrl + "/faq",
    })
      .then(function (response) {
        if (response.status === 200 && response.data) {
          setFaqs(response.data.faqs);
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  }, []);

  return (
    <Layout title={"FAQ"}>
      <Container maxWidth={"lg"}>
        <Box mt={2} mb={4}>
          <Box mb={4} className={classes.title}>
            FAQ
          </Box>
          {faqs.map((faq) => {
            return (
              <CollapseCard key={faq.id} title={faq.question}>
                <Box dangerouslySetInnerHTML={{ __html: faq.answer }} />
              </CollapseCard>
            );
          })}
        </Box>
      </Container>
    </Layout>
  );
};

export default FaqPage;
