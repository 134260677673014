// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase, set, ref, onValue } from "firebase/database";

// DEV
// const firebaseConfig = {
//   apiKey: "AIzaSyC5hAR9hyVgGttJuF04q3XEA73nYJ-tciU",
//   authDomain: "videonalejvarny-dev.firebaseapp.com",
//   databaseURL:
//     "https://videonalejvarny-dev-default-rtdb.europe-west1.firebasedatabase.app",
//   projectId: "videonalejvarny-dev",
//   storageBucket: "videonalejvarny-dev.appspot.com",
//   messagingSenderId: "748166661980",
//   appId: "1:748166661980:web:43bcbfd41c7664afdee399",
// };

// PROD
const firebaseConfig = {
  apiKey: "AIzaSyAVLFqxzqyYFM5XzUWQ340sxcf4WjZ4wuo",
  authDomain: "videonalejvarny-5eb25.firebaseapp.com",
  databaseURL:
    "https://videonalejvarny-5eb25-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "videonalejvarny-5eb25",
  storageBucket: "videonalejvarny-5eb25.appspot.com",
  messagingSenderId: "43070397038",
  appId: "1:43070397038:web:6c87f07fb2401900b1e47f",
};

const app = initializeApp(firebaseConfig);

const db = getDatabase(app);

export const writeLogin = (path, value, onFulfilled, onRejected) => {
  set(ref(db, path), value).then(onFulfilled).catch(onRejected);
};

export const readLogin = (path, callback) => {
  onValue(ref(db, path), callback);
};
