import React from "react";
import Routes from "./Routes";
import { ThemeProvider } from "@material-ui/styles";
import { CookiesProvider } from "react-cookie";
import ReactGA from "react-ga";
import { useSelector } from "react-redux";
import { GTMProvider } from "@elgorditosalsero/react-gtm-hook";
import CookieConsent from "react-cookie-consent";
import { green } from "@material-ui/core/colors";
import ForceLogout from "./components/ForceLogout/ForceLogout";
import { Box } from "@material-ui/core";

ReactGA.initialize("UA-184804437-1");
ReactGA.pageview(window.location.pathname + window.location.search);

export default function App() {
  const theme = useSelector((state) => state.app.theme);

  return (
    <GTMProvider state={{ id: "GTM-53FK9LQ" }}>
      <CookiesProvider>
        <ThemeProvider theme={theme}>
          <Routes />
          <CookieConsent
            enableDeclineButton
            location="bottom"
            buttonText="Rozumím"
            buttonStyle={{ background: green[500] }}
            declineButtonText={"Odmítnout"}
            declineButtonStyle={{ background: "transparent" }}
          >
            Tyto webové stránky používají soubory cookies k zajištění funkčnosti
            webu a s Vaším souhlasem i mj. k personalizaci obsahu. Kliknutím na
            tlačítko „Rozumím“ souhlasíte s využívaním cookies a předáním údajů
            o chování na webu pro zobrazení cílené reklamy na sociálních sítích
            a reklamních sítích na dalších webech.
          </CookieConsent>
        </ThemeProvider>
      </CookiesProvider>
    </GTMProvider>
  );
}
