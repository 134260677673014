import React from "react";
import {
  Box,
  Card,
  Container,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Layout from "../components/Layout/Layout";

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: "center",
    fontSize: 36,
    fontWeight: 800,
    color: "#000",
    lineHeight: 1.15,
    [theme.breakpoints.up("md")]: {
      fontSize: 58,
    },
  },
  image: {
    borderRadius: "50%",
    width: 180,
  },
  name: {
    marginTop: theme.spacing(2),
    textAlign: "center",
    fontSize: 24,
    fontWeight: 500,
    color: "#000",
    lineHeight: 1.25,
    marginBottom: theme.spacing(2),
  },
}));

const AboutUsView = () => {
  const classes = useStyles();

  return (
    <Layout title={"O nás"}>
      <Container maxWidth={"lg"}>
        <Box mt={2} mb={4}>
          <Box mb={4} className={classes.title}>
            O nás
          </Box>
          <Typography>
            <Box>
              <strong>Provozovatel</strong>: VIDEONALEJVÁRNY s.r.o.
            </Box>
            <Box>
              <strong>IČO</strong>: 09644211
            </Box>
            <Box>
              <strong>Sídlo</strong>: Okružní 988, 517 21 Týniště nad Orlicí
            </Box>
            <Box>
              <strong>Spisová značka</strong>: C 46600 vedená u Krajského soudu
              v Hradci Králové
            </Box>
          </Typography>
          <Typography style={{ marginTop: 32 }}>
            Za platformu Videonalejvárny nemůže Kalousek, ale člověk, co má málo
            vlasů a hodně vousů a jmenuje Fífa. Za platformou Videonalejvárny
            stál v roce 2020 pár dvou řízků, Fífa a Norbert. Fífa se v té době
            již 8 let angažoval v doučování vysokoškolských předmětů a Norbert
            zase ve stříhání videa. Výsledkem jsou Videonalejvárny – platforma
            pro vysokoškolské a středoškolské studenty. Studentům naše výuková
            videa a další materiály ulehčují život a dávají více než vyučující
            ve školách. Učit umí každý, ale naučit, to chce znalosti, cit a
            trošku kouzelnického umu. Vše máme, nebojte se sami přesvědčit.
          </Typography>
          <Grid container spacing={2} style={{ marginTop: 24 }}>
            <Grid item xs={12} md={6} style={{ textAlign: "center" }}>
              <Card variant={"outlined"} style={{ padding: 24 }}>
                <img className={classes.image} src={"/bio_fifa.jpg"} />
                <Typography className={classes.name}>Fífa</Typography>
                {/*<Typography className={classes.bio}>Hlavní obličej</Typography>*/}
                <Typography style={{ marginBottom: 8 }}>
                  Doučovat studenty začal v roce 2014 po úspěšném zdolání
                  Účetnictví na VŠE, kdy doučoval pár kamarádek. Během několika
                  let se ale z pár kamarádek stalo pár stovek až tisíc lidí z
                  VŠE, co Fífu navštívili z nejrůznějších důvodů. Někdo jen ze
                  zvědavosti, někdo protože potřeboval dobrou známku a někdo
                  proto, že látku nechápal.
                </Typography>
                <Typography style={{ marginBottom: 8 }}>
                  Spousta studentů taky využívá Fífovy sbírky, který dává ke
                  stažení for free. První sbírka vznikla v roce 2015 s názvem{" "}
                  <i>„Accounting’n’shit“</i> a jednalo se o řešené závěrečné
                  testy. Dnes už těch sbírek je několik a nejenom na Účetnictví
                  I., ale i na další předměty jako Manažerské účetnictví,
                  Účetnictví II., nebo Mikroekonomii, které Fífa také doučuje a
                  to svým svérázným způsobem.
                </Typography>
                <Typography>
                  V případě jakýchkoliv otázek mě neváhejte kontaktovat na
                  emailové adrese{" "}
                  <a
                    href={"mailto:fifakerhart@hotmail.com"}
                    style={{ textDecoration: "underline" }}
                  >
                    fifakerhart@hotmail.com
                  </a>
                  .
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} style={{ textAlign: "center" }}>
              <Card variant={"outlined"} style={{ padding: 24 }}>
                <img className={classes.image} src={"/bio_claudie.jpg"} />
                <Typography className={classes.name}>Claudie</Typography>
                <Typography style={{ marginBottom: 8 }}>
                  Studenti jsou pro mě na prvním, druhém i třetím místě.
                  Doučování nejen matematiky se věnuji již více než 8 let.
                  Zkušenost mám jak s průběžným doučováním během celého školního
                  roku, tak i s přípravou na přijímací zkoušky na gymnázia,
                  maturity či opravné reparáty. Mým cílem je, aby matika
                  přestala být noční můrou. Možná si jí díky podrobnému
                  vysvětlení všech témat oblíbíš. Anebo ti videa minimálně
                  pomůžou zvládnout matiku ve škole a prolézt bez větších
                  zádrhelů.
                </Typography>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Layout>
  );
};

export default AboutUsView;
